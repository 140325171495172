import React, { useState } from 'react';
import './DOCRModal.css';

const price = {
  starter: 0.00,
  basic: 5.00,
  professional: 20.00
};

function DOCRModal({ closeModal, addQuoteItem, formatPrice }) {
  const [docrOption, setDocrOption] = useState('starter');
  const [name, setName] = useState('');
  const [overage, setOverage] = useState('');
  
  const overagePrice = 20.48;

  // Conversion factor from GiB to GB
  const conversionFactorGiBtoGB = 1.0737;

  // Conversion factor from TiB to TB
  const conversionFactorTiBtoTB = 1.0995;

  // Conversion factor from PiB to PB
  const conversionFactorPiBtoPB = 1.1259;

  // Calculate the total price
  const calculateTotalPrice = () => {
    return formatPrice(price[docrOption] + (docrOption === 'starter' ? 0 : overage * overagePrice) || 0);
  };

  // Handle adding the selected plan to the quote
  const handleAddDOCR = () => {
    const docrDetails = {
      starter: {
        description: 'Starter Container Registry plan with 1 repository and 500 MiB storage.',
        features: '',
      },
      basic: {
        description: `Basic Container Registry plan with 5 repositories and ${overage > 0 ? (overage).toLocaleString(undefined, {maximumFractionDigits: 0}) + ' TiB' : '5 GiB'} storage.`,
        features: '',
      },
      professional: {
        description: `Professional Container Registry plan with unlimited repositories and ${overage > 0 ? (overage).toLocaleString(undefined, {maximumFractionDigits: 0}) + ' TiB' : '100 GiB'} storage.`,
        features: '',
      }
    };

    const selectedDocr = docrDetails[docrOption] || {};
    const totalPrice = calculateTotalPrice();

    addQuoteItem({
      serviceType: 'Container Registry',
      name: name,
      description: selectedDocr.description,
      features: selectedDocr.features,
      priceMonthly: totalPrice,
      quantity: 1,
      totalPrice
    });
    closeModal(); 
  };

  return (
    <div id="modal" className="modal docr-modal">
      <div id="modal-content" className="modal-content">
        <span id="close" className="close" onClick={closeModal}>&times;</span>
        <div id="modal-header" className="modal-header flex flex-between flex-center">
          <div>
            <h1>Add Container Registry</h1>
          </div>
          <div id="modal-item-name" className="modal-item-name">
            <input
              type="text"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter item name or description"
              maxLength="50"
            />
          </div>
        </div>
        <div id="modal-body" className="modal-body">
          <h5>SELECT CONTAINER REGISTRY PLAN</h5>
          <div id="modal-radio-buttons" className="modal-radio-buttons flex flex-column gap-dot5">
            <div
              className={`radio-button ${docrOption === 'starter' ? 'radio-button-active' : ''}`}
              onClick={() => {
                setDocrOption('starter');
                setOverage(undefined);
              }}            >
              <div className="flex flex-between flex-center">
                <div className="font-weight-600">Starter</div>
                <div id="price" class="price">
                    <span class="dollar">$</span>
                    <span class="cost">{formatPrice(price.starter)}</span>
                    <span class="period">/mo</span>
                </div>
              </div>
              <div className="font-blueGray font-size-dot8 font-weight-400" style={{ marginTop: '.1rem' }}>1 repository and 500 MiB storage.</div>
            </div>
            <div
              className={`radio-button ${docrOption === 'basic' ? 'radio-button-active' : ''}`}
              onClick={() => setDocrOption('basic')}
            >
              <div className="flex flex-between flex-center">
                <div className="font-weight-600">Basic</div>
                <div id="price" class="price">
                  <span class="dollar">$</span>
                  <span class="cost">{formatPrice(price.basic)}</span>
                  <span class="period">/mo</span>
                </div>
              </div>
              <div className="font-blueGray font-size-dot8 font-weight-400" style={{ marginTop: '.1rem' }}>5 repositories and 5 GiB storage.</div>
            </div>
            <div
              className={`radio-button ${docrOption === 'professional' ? 'radio-button-active' : ''}`}
              onClick={() => setDocrOption('professional')}
            >
              <div className="flex flex-between flex-center">
                <div className="font-weight-600">Professional</div>
                <div id="price" class="price">
                  <span class="dollar">$</span>
                  <span class="cost">{formatPrice(price.professional)}</span>
                  <span class="period">/mo</span>
                </div>
              </div>
              <div className="font-blueGray font-size-dot8 font-weight-400" style={{ marginTop: '.1rem' }}>Unlimited repositories and 100 GiB storage.</div>
            </div>
          </div>
          {docrOption !== 'starter' && <div>
            <h5>SELECT STORAGE OVERAGE SIZE</h5>
            <div id="calculation-container" className="calculation-container">
              <div id="calculation-container-price" className="calculation-container-price">
                <div className="price">
                  <span className="dollar dollar-large">$</span>
                  <span className="cost cost-large">{overage ? formatPrice(parseInt(overage, 10) * overagePrice) : '0'}</span>
                  <span className="period period-large">/mo</span>
                </div>
                <div>
                  <span>
                    {overage >= 1024
                      ? `${((overage) / 1024).toFixed(2).replace(/\.0+$/, '')} PiB (${((overage) / 1024 * conversionFactorPiBtoPB).toFixed(2).replace(/\.0+$/, '')} PB)`
                      : overage > 0
                      ? `${overage} TiB (${(overage * conversionFactorTiBtoTB).toFixed(0)} TB)`
                      : `_ TiB ( _ TB)`}
                  </span>
                </div>
              </div>
              <div id="calculation-container-input" className="calculation-container-input">
                <input 
                  type="text" 
                  spellCheck="false" 
                  required="" 
                  placeholder="Enter overage in TiB"  
                  value={overage}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) { // Only allow digits
                      const numValue = parseInt(value, 10);
                      if (!isNaN(numValue) && numValue >= 0 && numValue <= 10240) {
                        setOverage(value);
                      } else if (value === '') {
                        setOverage('');
                      }
                    }
                  }}
                />
                <div id="floatlabel-price" className="floatlabel-price">
                  <label>Enter overage in TiB</label>
                </div>
              </div>
            </div>
          </div>}
        </div>
        <div id="modal-footer" className="modal-footer flex flex-between flex-center">
          <div id="action-button" className="action-button">
            <button className="button-blue" onClick={handleAddDOCR}>
              <span>Add Container Registry Plan</span>
            </button>
          </div>
          <div id="price" className="price">
            <span className="dollar dollar-large">$</span>
            <span className="cost cost-large">
              {calculateTotalPrice()}
            </span>
            <span className="period period-large">/mo</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DOCRModal;