import React, { useState, useRef } from 'react';
import './LoadBalancersModal.css';

function LoadBalancersModal({ closeModal, addQuoteItem, formatPrice }) {
  const [quantityRLB, setQuantityRLB] = useState(1);
  const [quantityGLB, setQuantityGLB] = useState(1);
  const [name, setName] = useState(''); // New state for the name input
  const quantityInputRef = useRef(null);
  const [nodesRLB, setNodesRLB] = useState('');
  const [requestsGLB, setRequestsGLB] = useState(''); // Default value for included requests (in millions)
  const [dataTransferGLB, setDataTransferGLB] = useState(''); // Default value for included data transfer (in TBs)
  const [activeTab, setActiveTab] = useState('Regional');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Handle quantity change for RLB
  const handleQuantityRLBChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 1 && Number(value) <= 1000)) {
      setQuantityRLB(value);
    }
  };

  // Handle quantity blur for RLB
  const handleQuantityRLBBlur = () => {
    if (quantityRLB === '' || quantityRLB < 1) {
      setQuantityRLB(1);
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  };

  // Handle quantity change for GLB
  const handleQuantityGLBChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 1 && Number(value) <= 1000)) {
      setQuantityGLB(value);
    }
  };

  // Handle quantity blur for GLB
  const handleQuantityGLBBlur = () => {
    if (quantityGLB === '' || quantityGLB < 1) {
      setQuantityGLB(1);
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  };

  const handleAddRegionalLoadBalancers = () => {
    const numNodesRLB = parseInt(nodesRLB, 10);
    if (!isNaN(numNodesRLB) && numNodesRLB > 0 && numNodesRLB <= 100) {
      addQuoteItem({
        serviceType: 'Load Balancers',
        name: name, // Use the value from the new input field
        description: `Regional Load Balancer with ${numNodesRLB} nodes.`,
        features: '',
        priceMonthly: formatPrice(numNodesRLB * 12), 
        quantity: quantityRLB,
        totalPrice: formatPrice(numNodesRLB * 12 * quantityRLB)
      });
      closeModal(); // Close the modal after adding the quote item
    }
  };

  // Included requests and transfer
  const includedRequestsGLB = 25;
  const includedDataTransferGLB = 1;

  // Calculate costs for GLB
  const RequestsGLB = parseInt(requestsGLB, 10); // Millions of requests
  const DataTransferGLB = parseInt(dataTransferGLB, 10); // TBs of transfer

  // Calculate excess requests beyond 25 million
  const excessRequestsGLB = RequestsGLB > includedRequestsGLB ? (RequestsGLB - includedRequestsGLB) : 0;
  const requestsCostGLB = excessRequestsGLB > 0 ? excessRequestsGLB * 0.7 : 0;

  // Calculate excess data transfer beyond 1 TB (1000 GB)
  const excessDataTransferGLB = DataTransferGLB > includedDataTransferGLB ? DataTransferGLB - includedDataTransferGLB : 0;
  const dataTransferCostGLB = excessDataTransferGLB > 0 ? excessDataTransferGLB * 20 : 0;

  const basePrice = 15; // Base price for GLB
  const totalPrice = basePrice + requestsCostGLB + dataTransferCostGLB;

  const handleAddGlobalLoadBalancers = () => {
    addQuoteItem({
      serviceType: 'Load Balancers',
      name: name,
      description: `Global Load Balancer with ${(includedRequestsGLB + excessRequestsGLB).toLocaleString()} million requests and ${(includedDataTransferGLB + excessDataTransferGLB).toLocaleString()} TB data transfer per month.`,
      features: '',
      priceMonthly: formatPrice(totalPrice),
      quantity: quantityGLB,
      totalPrice: formatPrice(totalPrice * quantityGLB)
    });
    closeModal();
  };


  return (
    <div id="modal" className="modal load-balancers-modal">
      <div id="modal-content" className="modal-content">
        <span id="close" className="close" onClick={closeModal}>&times;</span>
        <div id="modal-header" className="modal-header flex flex-between flex-center">
          <div>
            <h1>
              Add Load Balancers
            </h1>
          </div>
          <div id="modal-item-name" className="modal-item-name">
            <input
              type="text"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter item name or description"
              maxLength="50"
            />
          </div>
        </div>
        <div id="modal-tabs" className="modal-tabs">
          <button
            className={`tab-button ${activeTab === 'Regional' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Regional')}
          >
            Regional
          </button>
          <button
            className={`tab-button ${activeTab === 'Global' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Global')}
          >
            Global
          </button>
        </div>
        <div id="modal-body" className="modal-body">
          {activeTab === 'Regional' && (
            <div>
              <h5>CONFIGURE LOAD BALANCER</h5>
              <div id="calculation-container" className="calculation-container">
                <div id="calculation-container-price" className="calculation-container-price flex">
                    <div className="price">
                      <span className="dollar dollar-large">$</span>
                      <span className="cost cost-large">{nodesRLB ? formatPrice(parseInt(nodesRLB * 12, 10)) : '0'}</span>
                      <span className="period period-large">/mo</span>
                    </div>
                    <div>
                      <span>
                        {nodesRLB === "" 
                          ? "0 requests per second"
                          : nodesRLB * 10000 >= 1000000
                            ? `${((nodesRLB * 10000) / 1000000)} million requests per second`
                            : `${(nodesRLB * 10000)} requests per second`}
                      </span>
                    </div>
                  </div>
                <div id="calculation-container-input" className="calculation-container-input">
                  <input 
                    type="text" 
                    spellCheck="false" 
                    required 
                    placeholder="Enter number of nodes"  
                    value={nodesRLB}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) { // Only allow digits
                        const numValue = parseInt(value, 10);
                        if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
                          setNodesRLB(value);
                        } else if (value === '') {
                          setNodesRLB('');
                        }
                      }
                    }}
                  />
                  <div id="floatlabel-price" className="floatlabel-price">
                    <label>Enter number of nodes</label>
                  </div>
                </div>
              </div>
              <h5>SELECT LOAD BALANCER QUANTITY</h5>
              <input
                ref={quantityInputRef}
                type="number"
                className="input"
                value={quantityRLB}
                onChange={handleQuantityRLBChange}
                onBlur={handleQuantityRLBBlur}
                min="1"
                max="1000"
              />
            </div>
          )}
          {activeTab === 'Global' && (
            <div>
              <div className="font-blueGray font-weight-400">
                Global load balancers cost $15 and include 25 million requests and 1 TB of data transfer per month.
              </div>
              <h5>SELECT LOAD BALANCER SIZE</h5>
              <div className="flex flex-start gap-1">
                <div id="calculation-container" className="calculation-container">
                  <div id="calculation-container-price" className="calculation-container-price flex">
                    <div className="price">
                      <span className="dollar dollar-large">$</span>
                      <span className="cost cost-large">{requestsCostGLB ? formatPrice(requestsCostGLB) : '0'}</span>
                      <span className="period period-large">/mo</span>
                    </div>
                    <div>
                      <span>
                        {includedRequestsGLB + excessRequestsGLB >= 1000
                          ? `${((includedRequestsGLB + excessRequestsGLB) / 1000)} billion requests`
                          : `${(includedRequestsGLB + excessRequestsGLB)} million requests`}
                      </span>
                    </div>
                  </div>
                  <div id="calculation-container-input" className="calculation-container-input">
                    <input 
                      type="text" 
                      spellCheck="false" 
                      required 
                      placeholder="Enter requests per month"  
                      value={requestsGLB}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) { // Only allow digits
                          const numValue = parseInt(value, 10);
                          if (!isNaN(numValue) && numValue >= 0 && numValue <= 100000) {
                            setRequestsGLB(value);
                          } else if (value === '') {
                            setRequestsGLB('');
                          }
                        }
                      }}
                    />
                    <div id="floatlabel-price" className="floatlabel-price">
                      <label>Enter requests in millions</label>
                    </div>
                  </div>
                </div>

                <div id="calculation-container" className="calculation-container">
                  <div id="calculation-container-price" className="calculation-container-price flex">
                    <div className="price">
                      <span className="dollar dollar-large">$</span>
                      <span className="cost cost-large">{dataTransferCostGLB ? formatPrice(dataTransferCostGLB) : '0'}</span>
                      <span className="period period-large">/mo</span>
                    </div>
                    <div>
                      <span>
                        {includedDataTransferGLB + excessDataTransferGLB >= 1000
                          ? `${((includedDataTransferGLB + excessDataTransferGLB) / 1000)} PB transfer`
                          : `${(includedDataTransferGLB + excessDataTransferGLB)} TB transfer`}
                      </span>
                    </div>
                  </div>
                  <div id="calculation-container-input" className="calculation-container-input">
                    <input 
                      type="text" 
                      spellCheck="false" 
                      required 
                      placeholder="Enter data transfer per month"  
                      value={dataTransferGLB}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) { // Only allow digits
                          const numValue = parseInt(value, 10);
                          if (!isNaN(numValue) && numValue >= 0 && numValue <= 10000) {
                            setDataTransferGLB(value);
                          } else if (value === '') {
                            setDataTransferGLB('');
                          }
                        }
                      }}
                    />
                    <div id="floatlabel-price" className="floatlabel-price">
                      <label>Enter data transfer in TB</label>
                    </div>
                  </div>
                </div>
              </div>

              <h5>SELECT LOAD BALANCER QUANTITY</h5>
              <input
                ref={quantityInputRef}
                type="number"
                className="input"
                value={quantityGLB}
                onChange={handleQuantityGLBChange}
                onBlur={handleQuantityGLBBlur}
                min="1"
                max="1000"
              />
            </div>            
          )}
        </div>
        <div id="modal-footer" className="modal-footer flex flex-between flex-center">
          <div id="action-button" className="action-button">
            <button
              className="button-blue"
              onClick={() => {
                if (activeTab === 'Regional') {
                  handleAddRegionalLoadBalancers();
                } else if (activeTab === 'Global') {
                  handleAddGlobalLoadBalancers();
                }
              }}
              disabled={activeTab === 'Regional' && (!nodesRLB || nodesRLB <= 0)} // Disable button for Regional Load Balancers if nodesRLB is empty or <= 0
            >
              {activeTab === 'Regional' ? <span>Add Regional Load Balancers</span> : <span>Add Global Load Balancers</span>}
            </button>
          </div>
          <div id="price" className="price">
            <span className="dollar dollar-large">$</span>
            <span className="cost cost-large">
              {activeTab === 'Regional' 
                ? nodesRLB ? formatPrice(parseInt(nodesRLB, 10) * 12 * quantityRLB) : '0' 
                : formatPrice((basePrice + requestsCostGLB + dataTransferCostGLB) * quantityGLB)
              }
            </span>
            <span className="period period-large">/mo</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadBalancersModal;