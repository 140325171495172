import React, { useState, useRef, useEffect } from 'react';
import './AppPlatformModal.css';

function AppPlatformModal({ closeModal, addQuoteItem, formatPrice, plansAppPlatform }) {
  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [containers, setContainers] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [quantityEgressIP, setQuantityEgressIP] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [overage, setOverage] = useState('');
  const searchInputRef = useRef(null);
  const quantityInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState('Apps');
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const egressIpPrice = 25;
  const overagePrice = 20.48;

  // Conversion factor from GiB to GB
  const conversionFactorGiBtoGB = 1.0737;

  // Conversion factor from TiB to TB
  const conversionFactorTiBtoTB = 1.0995;

  // Conversion factor from PiB to PB
  const conversionFactorPiBtoPB = 1.1259;

  // Focus and select the quantity input when a plan is selected
  useEffect(() => {
    if (selectedPlan) {
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  }, [selectedPlan]);

  // Filter plans based on the search term by applying regex across various plan attributes
  const filteredPlans = plansAppPlatform.filter(plan => {
    
    // Create a regex from the search term, escaping special characters to prevent regex errors
    const regex = new RegExp(searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    
    // Test regex against plan fields, converting numeric fields to string with descriptive text
    return (
      regex.test(plan.Description) ||  // Check plan description
      regex.test(`${plan.VCPUs} vCPU`) ||  // Check virtual CPUs with 'vCPU' appended
      regex.test(`${plan.Memory} GiB Memory`) ||  // Check memory size with 'GiB Memory' appended
      regex.test(`${plan.Transfer} GiB Transfer`) ||  // Check data transfer limit with 'GiB Transfer' appended
      regex.test(`$${plan['Price Monthly']} Monthly`)  // Check monthly price with 'Monthly' appended
    );
  });

  // Handle plan selection
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setShowDropdown(false);
    setSearchTerm(''); // Clear the search term after selection
    setQuantity(1); // Set default quantity to 1
  };

	// Function to increment Containers by 1, capped at the maximum limit
	const handleContainersIncrement = () => {
    const maxContainers = 100;
    setContainers(prevContainers => {
        let newContainers = prevContainers < maxContainers ? prevContainers + 1 : prevContainers;
        calculateTotalPrice(selectedPlan, newContainers);
        return newContainers;
    });
	};

	// Function to decrement Containers by 1, capped at the minimum limit
	const handleContainersDecrement = () => {
    const minContainers = 1;
    setContainers(prevContainers => {
        let newContainers = prevContainers > minContainers ? prevContainers - 1 : prevContainers;
        calculateTotalPrice(selectedPlan, newContainers);
        return newContainers;
    });
	};

  // Handle Containers change
  const handleContainersChange = (e) => {
    const inputString = e.target.value;
    const value = parseInt(inputString, 10);

    if (inputString === "") {
        setContainers(""); // Allow the field to be empty while typing
    } else if (isNaN(value) || value < 1 || value > 100) {
        setContainers(1); // Default to 1 when out of range or invalid entry is completed
        calculateTotalPrice(selectedPlan, 1); // Recalculate price on input completion
    } else {
        setContainers(value);
        calculateTotalPrice(selectedPlan, value); // Recalculate price on valid input
    }
  };
	
	// Handle Containers blur
	const handleContainersBlur = () => {
    let validContainers = Math.min(Math.max(1, containers), 100);
    setContainers(validContainers); // Adjust to valid number of containers on blur
    calculateTotalPrice(selectedPlan, validContainers); // Recalculate price on blur
	};  

  // Handle quantity change for Apps
  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 1 && Number(value) <= 10000)) {
      setQuantity(value);
    }
  };

  // Handle quantity blur (when input loses focus) for Apps
  const handleQuantityBlur = () => {
    if (quantity === '' || quantity < 1) {
      setQuantity(1);
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  };

  // Handle quantity change for Egress IPs
  const handleQuantityEgressIPChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 1 && Number(value) <= 10000)) {
      setQuantityEgressIP(value);
    }
  };

  // Handle quantity blur (when input loses focus) for Egress IPs
  const handleQuantityEgressIPBlur = () => {
    if (quantityEgressIP === '' || quantityEgressIP < 1) {
      setQuantityEgressIP(1);
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  };

  // Calculate the total price
  const calculateTotalPrice = () => {
    if (!selectedPlan) return 0;
    let price = parseFloat(selectedPlan['Price Monthly']);
    return formatPrice(price * (containers || 1) * (quantity || 1));
  };

  const totalPrice = calculateTotalPrice();

  // Handle adding the selected App to the quote
  const handleAddApp = () => {
    if (selectedPlan) {
      let description = `${selectedPlan.Description} - ${selectedPlan.VCPUs} vCPU / ${selectedPlan.Memory} GiB Memory / ${selectedPlan.Transfer} GiB Egress.`;
      let features = '';    
      if (selectedPlan.Description === 'autoscaling') {
        features += '';
      }

      addQuoteItem({
        serviceType: 'App Platform Apps',
        name: name,
        description: description,
        features: features,
        transfer: selectedPlan.Transfer * 1/1024 * containers,
        priceMonthly: formatPrice(calculateTotalPrice().replace(/,/g, '') / quantity),
        quantity: quantity || 1,
        totalPrice
      });
      closeModal(); // Close the modal after adding the quote item
    }
  };

  // Handle adding Egress IPs to the quote
  const handleAddEgressIP = () => {
    addQuoteItem({
      serviceType: 'App Platform Egress IPs',
      name: name,
      description: 'Two dedicated Egress IPs for an App.',
      priceMonthly: egressIpPrice,
      quantity: quantityEgressIP,
      totalPrice: egressIpPrice * quantityEgressIP
    });
    closeModal(); // Close the modal after adding the quote item
  };

    // Handle adding Overage to the quote
  const handleAddOverage = () => {
    const numOverage = parseInt(overage, 10);
    if (!isNaN(numOverage) && numOverage > 0 && numOverage <= 10240) {
      addQuoteItem({
        serviceType: 'App Platform Egress Overage',
        name: name,
        description: `Overage of ${(numOverage).toLocaleString()} TiB (${(numOverage * conversionFactorGiBtoGB).toLocaleString(undefined, {maximumFractionDigits: 0})} TB).`,
        transfer: numOverage,
        priceMonthly: formatPrice(numOverage * overagePrice),
        quantity: 1,
        totalPrice: formatPrice(numOverage * overagePrice)
      });
      closeModal();
    }
  }; 
  
  
  return (
    <div id="modal" className="modal app-platform-modal">
      <div id="modal-content" className="modal-content">
        <span id="close" className="close" onClick={closeModal}>&times;</span>
        <div id="modal-header" className="modal-header flex flex-between flex-center">
          <div>
            <h1>Add App Platform</h1>
          </div>
          <div id="modal-item-name" className="modal-item-name">
            <input
              type="text"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter item name or description"
              maxLength="50"
            />
          </div>
        </div>
        <div id="modal-tabs" className="modal-tabs">
          <button
            className={`tab-button ${activeTab === 'Apps' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Apps')}
          >
            Apps
          </button>
          <button
            className={`tab-button ${activeTab === 'Egress IPs' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Egress IPs')}
          >
            Egress IPs
          </button>
          <button
            className={`tab-button ${activeTab === 'Egress Overage' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Egress Overage')}
          >
            Egress Overage
          </button>
        </div>
        <div id="modal-body" className="modal-body">
          {activeTab === 'Apps' && (
            <div>
              <h5>SELECT APP PLAN</h5>
              <div id="search-container" className="search-container">
                <input
                  ref={searchInputRef}
                  type="text"
                  className="input"
                  placeholder="Search plans or click to view all"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setShowDropdown(true);
                  }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
                {showDropdown && filteredPlans.length > 0 && (
                  <ul id="dropdown" className="dropdown">
                    {filteredPlans.map((plan, index) => (
                      <li key={index} onClick={() => handlePlanSelect(plan)} className="flex font-darkGray">
                        <div className="dropdown-plans-description">
                          {plan.Description}
                        </div>
                        <div className="dropdown-plans-specifications">
                          {plan.VCPUs} vCPU, {plan.Memory} GiB Memory, {plan.Transfer} GiB Egress
                        </div>
                        <div id="price" className="price">
                          <span className="dollar">$</span>
                          <span>{formatPrice(parseInt(plan['Price Monthly'], 10))}</span>
                          <span className="period">/mo</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {showDropdown && filteredPlans.length === 0 && (
                  <ul id="dropdown" className="dropdown">
                    <li>No plans found</li>
                  </ul>
                )}
              </div>
              {selectedPlan && (
                <div>
                  <div id="card" className="card">
                    <div id="card-header" className="card-header flex flex-between flex-center">
                      <div>
                        <h5>SELECTED PLAN</h5>
                      </div>
                      <div id="price" className="price">
                        <span className="dollar">$</span>
                        <span className="cost">{selectedPlan['Price Monthly']}</span>
                        <span className="period">/mo</span>
                      </div>
                    </div>
                    <div id="card-body" className="card-body">
                      <p>{selectedPlan.Description}</p>
                      <p>{selectedPlan.VCPUs} vCPU / {selectedPlan.Memory} GiB Memory / {selectedPlan.Transfer} GiB Egress</p>
                    </div>
                  </div>

                  <h5>SELECT NUMBER OF CONTAINERS</h5>
                  <div className="scaler-container">
                      <div className="scaler-input-wrapper">
                          <div className="scaler-input">
                              <input 
                              type="number" 
                              className="scaler-input-field" 
                              name="storage-count" 
                              placeholder="" 
                              min="0"
                              max="100" 
                              aria-invalid="false" 
                              autoComplete="off" 
                              spellCheck="false" 
                              value={containers}
                              onChange={handleContainersChange}
                              onBlur={handleContainersBlur}
                              disabled={selectedPlan.Description.includes('Fixed')}
                            />
                          </div>
                      </div>
                      <div className="scaler-button-wrapper-up">
                          <button 
                              className="scaler-button-increment" 
                              onClick={handleContainersIncrement}
                              disabled={containers >= 100 || selectedPlan.Description.includes('Fixed')}
                          >
                              <svg className="scaler-icon-up" aria-hidden="true">
                                  <use href="#_sprite-plus"></use>
                              </svg>
                          </button>
                      </div>
                      <div className="scaler-button-wrapper-down">
                          <button 
                              className="scaler-button-decrement" 
                              onClick={handleContainersDecrement}
                              disabled={containers <= 1}
                          >
                              <svg className="scaler-icon-down" aria-hidden="true">
                                  <use href="#_sprite-minus"></use>
                              </svg>
                          </button>
                      </div>
                    </div>




                  <h5>SELECT APP QUANTITY</h5>
                  <input
                    ref={quantityInputRef}
                    type="number"
                    className="input"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleQuantityBlur}
                    min="1"
                    max="10000"
                  />
                </div>
              )}
            </div>
          )}
          {activeTab === 'Egress IPs' && (
            <div>
              <div id="alert-info" className="flex flex-center">
                <svg className="alert-info" aria-hidden="true">
                  <use href="#_sprite-alert-info"></use>
                </svg> 
                <div className="period font-blueGray font-weight-400">           
                  Egress IPs cost $25 per month per App.
                </div>
              </div>
              <h5>SELECT QUANTITY OF APPS WITH EGRESS IPs</h5>
              <input
                ref={quantityInputRef}
                type="number"
                className="input"
                value={quantityEgressIP}
                onChange={handleQuantityEgressIPChange}
                onBlur={handleQuantityEgressIPBlur}
                min="1"
                max="10000"
              />
            </div>
          )}
          {activeTab === 'Egress Overage' && (
            <div>
              <h5>SELECT OVERAGE SIZE</h5>
              <div id="calculation-container" className="calculation-container">
                <div id="calculation-container-price" className="calculation-container-price">
                  <div className="price">
                    <span className="dollar dollar-large">$</span>
                    <span className="cost cost-large">{overage ? formatPrice(parseInt(overage, 10) * overagePrice) : '0'}</span>
                    <span className="period period-large">/mo</span>
                  </div>
                  <div>
                    <span>
                      {overage >= 1024
                        ? `${((overage) / 1024).toFixed(2).replace(/\.0+$/, '')} PiB (${((overage) / 1024 * conversionFactorPiBtoPB).toFixed(2).replace(/\.0+$/, '')} PB)`
                        : overage > 0
                        ? `${overage} TiB (${(overage * conversionFactorTiBtoTB).toFixed(0)} TB)`
                        : `_ TiB ( _ TB)`}
                    </span>
                  </div>
                </div>
                <div id="calculation-container-input" className="calculation-container-input">
                  <input 
                    type="text" 
                    spellCheck="false" 
                    required="" 
                    placeholder="Enter overage in TiB"  
                    value={overage}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) { // Only allow digits
                        const numValue = parseInt(value, 10);
                        if (!isNaN(numValue) && numValue >= 0 && numValue <= 10240) {
                          setOverage(value);
                        } else if (value === '') {
                          setOverage('');
                        }
                      }
                    }}
                  />
                  <div id="floatlabel-price" className="floatlabel-price">
                    <label>Enter overage in TiB</label>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
        <div id="modal-footer" className="modal-footer flex flex-between flex-center">
          <div id="action-button" className="action-button">
            <button className="button-blue" disabled={
              !selectedPlan && activeTab === 'Apps' ||
              (activeTab === 'Egress Overage' && !overage)
            }
              onClick={() => {
                if (activeTab === 'Apps' && selectedPlan) {
                  handleAddApp();
                } else if (activeTab === 'Egress IPs') {
                  handleAddEgressIP();
                } else if (activeTab === 'Egress Overage' && overage) {
                  handleAddOverage();
                }
              }}
            >
              {activeTab === 'Apps' && <span>Add Apps</span>}
              {activeTab === 'Egress IPs' && <span>Add Egress IPs</span>}
              {activeTab === 'Egress Overage' && <span>Add Overage</span>}
            </button>
          </div>
          <div id="price" className="price">
            <span className="dollar dollar-large">$</span>
              {activeTab === 'Apps' && <span className="cost cost-large">{(totalPrice ? formatPrice(totalPrice) : '0')}</span>}
              {activeTab === 'Egress IPs' && <span className="cost cost-large">{egressIpPrice * quantityEgressIP}</span>}
              {activeTab === 'Egress Overage' && <span className="cost cost-large">{(overage ? formatPrice(parseInt(overage, 10) * overagePrice) : '0')}</span>}
            <span className="period period-large">/mo</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppPlatformModal;