import React, { useState, useRef, useEffect } from 'react';

function TabPostgreSQL({ name, addQuoteItem, formatPrice, plansDatabases, closeModal }) {
  const [quantity, setQuantity] = useState(1);
	const [standbyNodes, setStandbyNodes] = useState(0);
	const [isReadOnlyNode, setIsReadOnlyNode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [scalableStorage, setScalableStorage] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const searchInputRef = useRef(null);
  const quantityInputRef = useRef(null);

	// Define price per GiB for Scalable Storage
	const pricePerGiB = 0.2;

  // Focus and select the quantity input when a plan is selected
  useEffect(() => {
    if (selectedPlan) {
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  }, [selectedPlan]);

  // Filter plans based on the search term for PostgreSQL
  const filteredPlans = plansDatabases.filter(plan => {
    if (!plan.pgNodes) {
      return false;
    }
    // Create a regex from the search term, escaping special characters to prevent regex errors
    const regex = new RegExp(searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    return (
      regex.test(plan.Description) ||
      regex.test(plan.Slug) ||
      regex.test(`${plan.VCPUs} vCPU`) ||
      regex.test(`${plan.Memory} GiB Memory`) ||
      regex.test(`${plan.pgStorageMin} GiB Disk`) ||
      regex.test(plan.pgNVMe) ||
      regex.test(`${plan.Network} Gbps Network`) ||
      regex.test(`$${plan.pgPriceMin} /mo`)
    );
  });

  // Handle plan selection for PostgreSQL
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setScalableStorage(plan.pgStorageMin); // Set scalableStorage when a plan is selected
    setShowDropdown(false);
    setSearchTerm('');
    calculateTotalPrice(plan.pgStorageMin, plan); // Calculate price immediately when plan is selected
  };

	const calculateTotalPrice = (storage, plan, standbyNodes) => {
    if (!plan) return 0;

    let basePrice = parseFloat(plan.pgPriceMin);
    let additionalStorageCost = (storage - plan.pgStorageMin) * pricePerGiB;
    let totalPrice = basePrice + additionalStorageCost;

    if (standbyNodes !== '' && standbyNodes > 0) {
        totalPrice += totalPrice * standbyNodes;
    }

    setTotalPrice(formatPrice(totalPrice));
	};
	

	// Function to increment the storage size by 10, capped at the maximum limit
	const handleStorageIncrement = () => {
		const currentStorage = parseInt(scalableStorage, 10) || selectedPlan.pgStorageMin;
		const newStorage = Math.min(selectedPlan.pgStorageMax, currentStorage + 10);
		setScalableStorage(newStorage); // Update the storage size in the state
		calculateTotalPrice(newStorage, selectedPlan, standbyNodes); // Recalculate the total price with the new storage size
	};

	// Function to decrement the storage size by 10, capped at the minimum limit
	const handleStorageDecrement = () => {
		const currentStorage = parseInt(scalableStorage, 10) || selectedPlan.pgStorageMin;
		const newStorage = Math.max(selectedPlan.pgStorageMin, currentStorage - 10);
		setScalableStorage(newStorage); // Update the storage size in the state
		calculateTotalPrice(newStorage, selectedPlan, standbyNodes); // Recalculate the total price with the new storage size
	};

	// Function to increment Standby Nodes by 1, capped at the maximum limit
	const handleStandbyNodesIncrement = () => {
    const maxNodes = selectedPlan.pgNodes === "[1,2,3]" ? 2 : 0;
    setStandbyNodes(prevNodes => {
        let newNodes = prevNodes < maxNodes ? prevNodes + 1 : prevNodes;
        calculateTotalPrice(scalableStorage, selectedPlan, newNodes);
        return newNodes;
    });
	};

	// Function to decrement Standby Nodes by 1, capped at the minimum limit
	const handleStandbyNodesDecrement = () => {
    const minNodes = 0;
    setStandbyNodes(prevNodes => {
        let newNodes = prevNodes > minNodes ? prevNodes - 1 : prevNodes;
        calculateTotalPrice(scalableStorage, selectedPlan, newNodes);
        return newNodes;
    });
	};

	// Handle Standby Nodes change for PostgreSQL
	const handleStandbyNodesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (isNaN(value) || value < 0 || value > 2) {
        setStandbyNodes(0);
        calculateTotalPrice(scalableStorage, selectedPlan, 0); // Recalculate price on input change
    } else {
        setStandbyNodes(value);
        calculateTotalPrice(scalableStorage, selectedPlan, value); // Recalculate price on input change
    }
	};
	
	// Handle Standby Nodes blur for PostgreSQL
	const handleStandbyNodesBlur = () => {
    let validNodes = Math.min(Math.max(0, standbyNodes), selectedPlan.pgNodes === "[1,2,3]" ? 2 : 0);
    setStandbyNodes(validNodes); // Adjust to valid number of nodes on blur
    calculateTotalPrice(scalableStorage, selectedPlan, validNodes); // Recalculate price on blur
	};

	// Handle quantity change for PostgreSQL
	const handleQuantityChange = (e) => {
		const value = e.target.value;
		if (value === '' || (Number(value) >= 1 && Number(value) <= 1000)) {
			setQuantity(value);
		}
	};

	// Handle quantity blur for PostgreSQL
	const handleQuantityBlur = () => {
		if (quantity === '' || quantity < 1) {
			setQuantity(1);
			quantityInputRef.current.focus();
			quantityInputRef.current.select();
		}
	};

  // Handle adding the selected PostgreSQL plan to the quote
  const handleAdd = () => {
    if (selectedPlan) {
			let description = `${selectedPlan.Description} - ${selectedPlan.VCPUs} vCPU / ${selectedPlan.Memory} GiB Memory / ${scalableStorage} GiB ${scalableStorage > selectedPlan.pgStorageMin ? 'scalable storage' : (selectedPlan.pgNVMe === 'nvme' ? 'NVMe local storage' : 'SSD local storage')}.`;
      let features = '';
			if (isReadOnlyNode) {
				features += 'Read-only node for an existing database. ';
			} else if (standbyNodes === 1) {
				features += 'Primary node and standby node. ';
			} else if (standbyNodes === 2) {
					features += 'Primary node and two standby nodes. ';
			}

    	// Append the number of nodes to the description if standbyNodes is greater than 0
    	if (standbyNodes > 0) {
      	description = `${standbyNodes + 1}x ` + description;
    	}	

      addQuoteItem({
        serviceType: 'Databases - PostgreSQL',
        name: name,
        description: description,
        features: features,
        transfer: '',
        priceMonthly: totalPrice,
        quantity: quantity || 1,
        totalPrice: Number(totalPrice.toString().replace(/,/g, '')) * (quantity || 1)
    });
      closeModal(); // Close the modal after adding the quote item
    }
  };

  return (
    <div id="modal-body" className="modal-body">
      <h5>SELECT DATABASE PLAN</h5>
      <div id="search-container" className="search-container">
        <input
          ref={searchInputRef}
          type="text"
          className="input"
          placeholder="Search PostgreSQL plans or click to view all"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setShowDropdown(true);
          }}
          onClick={() => setShowDropdown(!showDropdown)}
        />
        {showDropdown && filteredPlans.length > 0 && (
          <ul id="dropdown" className="dropdown">
            {filteredPlans.map((plan, index) => (
              <li key={index} onClick={() => handlePlanSelect(plan)} className="flex font-darkGray">
                <div className="dropdown-plans-description">
                  {plan.Description}
                </div>
                <div className="dropdown-plans-specifications">
                  {plan.VCPUs} vCPU, {plan.Memory} GiB Memory, {plan.pgStorageMin} GiB Disk
                </div>
                <div id="price" className="price">
                  <span className="dollar">$</span>
                  <span>{formatPrice(parseInt(plan.pgPriceMin, 10))}</span>
                  <span className="period">/mo</span>
                </div>
              </li>
            ))}
          </ul>
        )}
        {showDropdown && filteredPlans.length === 0 && (
          <ul id="dropdown" className="dropdown">
            <li>No plans found</li>
          </ul>
        )}
      </div>
      {selectedPlan && (
        <div>
          <div id="card" className="card">
            <div id="card-header" className="card-header flex flex-between flex-center">
              <div>
                <h5>SELECTED PLAN</h5>
              </div>
              <div id="price" className="price">
                <span className="dollar">$</span>
                <span className="cost">{formatPrice(totalPrice)}</span>
                <span className="period">/mo</span>
              </div>
            </div>
            <div id="card-body" className="card-body">
              <p>{selectedPlan.Description}</p>
              <p>{selectedPlan.VCPUs} vCPU / {selectedPlan.Memory} GiB Memory / {scalableStorage} GiB Disk</p>
            </div>
          </div>
					<div className="flex gap-dot5">
						<div className="checkbox">
							<input 
									type="checkbox" 
									className="checkbox-input" 
									onChange={(e) => {
										setIsReadOnlyNode(e.target.checked);
										setStandbyNodes(0);
										calculateTotalPrice(scalableStorage, selectedPlan, 0);
									}}
							/>
						</div>
						<span className='font-size-dot8'>This is a read-only node for an existing database.</span>
					</div>

					<h5>SELECT SCALABLE STORAGE SIZE</h5>
          <div className="flex flex-start flex-center gap-2">
						<div className="stepper-container">
							<div className="stepper-input-wrapper">
									<div className="stepper-input">
											<input 
											type="number" 
											className="stepper-input-field" 
											name="storage-count" 
											placeholder="" 
											min={selectedPlan ? selectedPlan.pgStorageMin : undefined}
											max={selectedPlan ? selectedPlan.pgStorageMax : undefined} 
											step="10"
											aria-invalid="false" 
											autoComplete="off" 
											spellCheck="false" 
											value={scalableStorage} // Bind the input to state
											onChange={(e) => {
													const value = e.target.value;

													// Allow the field to be empty
													if (value === '') {
													setScalableStorage('');
													return;
													}

													const numericValue = parseInt(value, 10);

													// If the input is not a valid number, do nothing
													if (isNaN(numericValue)) {
													return;
													}

													setScalableStorage(numericValue);
											}}
											onBlur={(e) => {
												if (!selectedPlan) return; // Ensure a plan is selected before enforcing limits
										
												let value = parseInt(e.target.value, 10);
										
												// If the input is empty, reset to the minimum value without rounding up
												if (isNaN(value)) {
														setScalableStorage(selectedPlan.pgStorageMin);
														calculateTotalPrice(selectedPlan.pgStorageMin, selectedPlan, standbyNodes); // Calculate price on blur
														return;
												}
										
												const minStorage = selectedPlan.pgStorageMin;
												const maxStorage = selectedPlan.pgStorageMax;
										
												// Enforce minimum and maximum constraints without rounding up
												if (value <= minStorage) {
														setScalableStorage(minStorage);
														calculateTotalPrice(minStorage, selectedPlan, standbyNodes); // Calculate price on blur
												} else if (value >= maxStorage) {
														setScalableStorage(maxStorage);
														calculateTotalPrice(maxStorage, selectedPlan, standbyNodes); // Calculate price on blur
												} else {
														// Calculate the offset from the minimum value
														const offset = minStorage % 10;
										
														// Adjust rounding to consider the offset from the minimum value
														value = Math.ceil((value - offset) / 10) * 10 + offset;
										
														setScalableStorage(value);
														calculateTotalPrice(value, selectedPlan, standbyNodes); // Calculate price on blur
												}
											}}
										/>
									</div>
							</div>
							<div className="stepper-button-wrapper-up">
									<button 
											className="stepper-button-increment" 
											onClick={handleStorageIncrement}
											disabled={scalableStorage == selectedPlan.pgStorageMax} // Disable if current storage is at the maximum limit
									>
											<svg className="stepper-icon-up" aria-hidden="true">
													<use href="#_sprite-arrow-up"></use>
											</svg>
									</button>
							</div>
							<div className="stepper-button-wrapper-down">
									<button 
											className="stepper-button-decrement" 
											onClick={handleStorageDecrement}
											disabled={scalableStorage == selectedPlan.pgStorageMin} // Disable if current storage is at the minimum limit
									>
											<svg className="stepper-icon-down" aria-hidden="true">
													<use href="#_sprite-arrow-down"></use>
											</svg>
									</button>
							</div>
						</div>
						<div className='font-size-dot8'>
							<p className="font-weight-600">Storage Range</p>
							<p>{selectedPlan.pgStorageMin} GiB - {selectedPlan.pgStorageMax} GiB</p>
						</div>
					</div>

					<h5>SELECT STANDBY NODES</h5>
					<div className="scaler-container">
							<div className="scaler-input-wrapper">
									<div className="scaler-input">
											<input 
											type="number" 
											className="scaler-input-field" 
											name="storage-count" 
											placeholder="" 
											min="0"
											max={selectedPlan.pgNodes == "[1,2,3]" ? 2 : 0} 
											aria-invalid="false" 
											autoComplete="off" 
											spellCheck="false" 
											value={standbyNodes}
											onChange={handleStandbyNodesChange}
											onBlur={handleStandbyNodesBlur}
											disabled={isReadOnlyNode || selectedPlan.pgNodes == "[1]"}
										/>
									</div>
							</div>
							<div className="scaler-button-wrapper-up">
									<button 
											className="scaler-button-increment" 
											onClick={handleStandbyNodesIncrement}
											disabled={isReadOnlyNode || standbyNodes >= 2 || selectedPlan.pgNodes === "[1]"} // Disable if current standbyNodes is 2 or if the plan supports only 1 node
									>
											<svg className="scaler-icon-up" aria-hidden="true">
													<use href="#_sprite-plus"></use>
											</svg>
									</button>
							</div>
							<div className="scaler-button-wrapper-down">
									<button 
											className="scaler-button-decrement" 
											onClick={handleStandbyNodesDecrement}
											disabled={isReadOnlyNode || standbyNodes <= 0 || selectedPlan.pgNodes === "[1]"} // Disable if current standbyNodes is 0 or if the plan supports only 1 node
									>
											<svg className="scaler-icon-down" aria-hidden="true">
													<use href="#_sprite-minus"></use>
											</svg>
									</button>
							</div>
						</div>

          <h5>SELECT DATABASE QUANTITY</h5>
          <input
            ref={quantityInputRef}
            type="number"
            className="input"
            value={quantity}
            onChange={handleQuantityChange}
            onBlur={handleQuantityBlur}
            min="1"
            max="1000"
          />
				</div>
      )}
			<div id="modal-footer" className="modal-footer flex flex-between flex-center">
				<div id="action-button" className="action-button">
						<button
						className="button-blue"
						onClick={handleAdd}
						disabled={!selectedPlan} // Disable the button if no plan is selected
						>
						Add PostgreSQL
						</button>
				</div>
				<div id="price" className="price">
						<span className="dollar dollar-large">$</span>
						<span className="cost cost-large">{selectedPlan ? formatPrice(Number(totalPrice.toString().replace(/,/g, '')) * quantity) : '0'}</span>
						<span className="period period-large">/mo</span>
				</div>
			</div>
		</div>
  );
}

export default TabPostgreSQL;