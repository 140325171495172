import React, { useRef, useState, useEffect } from 'react';
import './GenerateQuoteModal.css';
import { PDFExport } from "@progress/kendo-react-pdf";
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';

// Conversion factor from TiB to TB
const conversionFactorTiBtoTB = 1.0995;

const GenerateQuoteModal = ({ closeModal, quoteItems, formatPrice, companyName, totalPrice, totalPriceAfterDiscount, totalTraffic, isDiscountApplied, discount }) => {
  // Reference to the PDFExport component
  const pdfExportComponent = useRef(null);

  // Function to trigger the PDF export
  const handleExportWithComponent = () => {
    pdfExportComponent.current.save();
  };

  // Hook to generate a new UUID when the modal opens
  useEffect(() => {
    setQuoteId(uuidv4());
  }, []);  

  // Function to create a state variable for the UUID
  const [quoteId, setQuoteId] = useState(uuidv4());

  // Get the current date
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });

  // Calculate the expiry date (e.g., 30 days from the current date)
  const expiryDate = new Date();
  expiryDate.setDate(currentDate.getDate() + 31);
  const formattedExpiryDate = expiryDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });

  const exportQuoteToCSV = () => {
    const quoteData = quoteItems.map((item, index) => [
      index + 1,
      `"${item.serviceType}"`,
      `"${item.name}"`,
      `"${item.description}"`,
      `"${item.features}"`,
      `"${formatPrice(item.priceMonthly)}"`,
      item.quantity,
      `"${formatPrice(item.totalPrice)}"`
    ]);

    const headers = ['Item', 'Service', 'Name', 'Description', 'Features', '"Price Monthly, $"', 'Quantity', '"Total Price, $"'];
    const rows = [
      headers,
      ...quoteData,
      ['', '', '', '', '', '', '', ''],
      ...(totalPrice > totalPriceAfterDiscount ? [
        ['', '', '', '"Total price before discount, $"', '', '', '', `"${formatPrice(totalPrice)}"`],
        ['', '', '', '"Discount, $"', '', '', '', `-"${formatPrice(totalPrice - totalPriceAfterDiscount)}"`]
      ] : []),
      ['', '', '', '"Total price, $"', '', '', '', `"${formatPrice(totalPriceAfterDiscount)}"`],      
      ...(totalTraffic > 0 ? [
        ['', '', '', '"Total Egress, TiB"', '', '', '', `"${totalTraffic.toLocaleString(undefined, {maximumFractionDigits: 0})}"`]
      ] : [])
    ];    

    // Convert rows to CSV format
    const csvContent = rows.map(e => e.join(",")).join("\n");

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Use file-saver to save the Blob as a file
    saveAs(blob, `do-estimate-${quoteId}.csv`);
  };

  // Function to handle the Share Link click to save quote to DigitalOcean Spaces
  const [shareableLink, setShareableLink] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  const handleShareLinkClick = async () => {
    const quoteData = {
      quoteId,
      companyName,
      quoteItems,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/save-quote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ quoteId, quoteData }),
      });      
  
      if (!response.ok) {
        throw new Error('Failed to save quote');
      }
  
      const result = await response.json();
  
      // Create the new shareable link
      const newShareableLink = `${process.env.REACT_APP_DOMAIN}/?quoteId=${quoteId}`;
  
      // Update the state with the new link
      setShareableLink(newShareableLink);
  
      // Copy the new link to the clipboard
      navigator.clipboard.writeText(newShareableLink);
  
      // Show the notification
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000); // Notification disappears after a few seconds
    } catch (error) {
      console.error('Error saving the quote:', error);
    }
  };  

  return (
    <div className="modal">
      <div className="modal-content generated-quote-modal">
        {/* Close button for the modal */}
        <span className="close" onClick={closeModal}>&times;</span>
        <div className="generated-quote-item-export-buttons flex">
        {/* Buttons to trigger export */}
          <div id="action-text" class="action-text" onClick={handleExportWithComponent}>Download as PDF</div>
          <div id="action-text" class="action-text" onClick={exportQuoteToCSV}>Download as CSV</div>
          <div id="action-text" className="action-text" onClick={handleShareLinkClick}>Save and Share</div>
          {showNotification && (
            <div className="notification">
              Link copied to clipboard
            </div>
          )}
        </div>

        {/* PDFExport component to wrap the content to be exported */}
        <PDFExport ref={pdfExportComponent} fileName={`do-estimate-${quoteId}.pdf`}>
          <section id="generated-quote" className="quote">
            <div id="generated-quote-header" className="generated-quote-header">
              <div className="flex flex-between flex-center">
                <div className="generated-quote-title">
                  <h1 className="margins-no">Price Estimate</h1>
                </div>
                <div className="generated-quote-logo"></div>
              </div>
              <div className="flex flex-between flex-center">
                <div className="header-footer-text-small">{companyName}</div>
                <div className="font-blue font-size-dot8">101 6th Ave, New York</div>
              </div>
            </div>
              
            <div className="generated-quote-totals flex flex-between">
              <div className="generated-quote-total-resources-container">
                <div>{totalTraffic !== 0 && (<h3>Total Resources</h3>)}</div>
                <div className="generated-quote-total-resources flex flex-column gap-dot5">
                {totalTraffic !== 0 && (
                  <div>
                    <span className="">{totalTraffic.toLocaleString(undefined, {maximumFractionDigits: 0})} TiB ({(totalTraffic * conversionFactorTiBtoTB).toLocaleString(undefined, {maximumFractionDigits: 0})} TB) Droplet/Kubernetes egress.</span>
                  </div>
                )}
                  <div>
                    <span></span>
                  </div>
                </div>
              </div>
              <div className="generated-quote-total-cost-container">
                <div><h3>Total Cost</h3></div>
                <div className="generated-quote-total-cost flex flex-right flex-column gap-dot5">
                  {isDiscountApplied && (
                    <>
                      <span className="header-footer-text-small">
                        ${formatPrice(totalPrice)} monthly before discount
                      </span>
                      <span className="header-footer-text-small">
                        -${formatPrice(totalPrice-totalPriceAfterDiscount)} monthly discount at {discount} 
                      </span>
                    </>
                  )}
                  <span className="header-footer-text-large">${formatPrice(totalPriceAfterDiscount)} monthly</span>
                </div>
              </div>  
            </div>
            <h3>Services</h3>
            <div id="quoteItems">
              {/* Display message if no items are in the quote */}
              {quoteItems.length === 0 ? (
                <p id="quote-placeholder" className="quote-placeholder">
                  No items in the Estimate. Please add a service to get started.
                </p>
              ) : (
                // Map through quote items and display each one
                quoteItems.map((item, index) => (
                  <div key={index} id="quote-item" className="quote-item">
                    <div id="quote-item-header" className="quote-item-header flex">
                      <div id="quote-item-index" className="quote-item-index">
                        Item {index + 1}
                      </div>
                      <div id="quote-item-service" className="quote-item-service">
                        <strong>{item.serviceType}</strong>
                      </div>
                      <div id="quote-item-name" className="quote-item-name">
                        {item.name}
                      </div>
                    </div>
                    <div id="quote-item-body" className="quote-item-body">
                      <div id="quote-item-description" className="quote-item-description flex flex-between">
                        <div>{item.description}</div>
                          <div id="price" className="price">
                            <span className="dollar">$</span>
                            <span className="cost">{formatPrice(item.totalPrice)}</span>
                            <span className="period">/mo</span>
                          </div>
                      </div>
                      {item.features && (
                        <div id="quote-item-features" className="quote-item-features">
                          {item.features}
                        </div>
                      )}
                      <div id="quote-item-quantity" className="quote-item-quantity">
                      {item.quantity} at ${formatPrice(item.priceMonthly)} /mo.
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div id="generated-quote-footer" className="generated-quote-footer flex">
              <div id="generated-quote-dates" className="generated-quote-dates flex">
                  <div id="generated-quote-issue" className="generated-quote-issue flex flex-between">
                    <span className="header-footer-text-small">Estimate:</span>
                    <span className="header-footer-text-small">{formattedCurrentDate}</span>
                  </div>
                  <div id="generated-quote-expiry" className="generated-quote-expiry flex flex-between">
                    <span className="header-footer-text-small">Expiry:</span>
                    <span className="header-footer-text-small">{formattedExpiryDate}</span>
                  </div>
              </div>
            </div>
            <div className="generated-quote-tos header-footer-text-tiny flex flex-column">
              <span>The Services outlined above are governed by DigitalOcean's Terms of Services, located at www.digitalocean.com/legal/terms, the "TOS".</span>
              <span>The Customer's use of the Services is subject to its acceptance of the TOS.</span>
              <span>This price estimate is for illustrative purposes only and is based on then current pricing and the estimated usage of the Customer at the time of its creation.</span> 
              <span>If the Customer uses DigitalOcean Services, they will pay the applicable fees based on actual usage and current rates.</span>
              {isDiscountApplied && (<span>Proposed discounts pending approval.</span>)}
            </div>
          </section>
        </PDFExport>
      </div>
    </div>
  );
};

export default GenerateQuoteModal;