import React, { useState, useRef } from 'react';
import './GenAIModal.css';

function GenAIModal({ closeModal, addQuoteItem, formatPrice, plansGenAI }) {
  const [activeTab, setActiveTab] = useState('Agents');
  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputTokens, setInputTokens] = useState('');
  const [outputTokens, setOutputTokens] = useState('');

  const inputTokenCost = selectedPlan && inputTokens > 0 ? inputTokens * selectedPlan['Rate Input'] : 0;
  const outputTokenCost = selectedPlan && outputTokens > 0 ? outputTokens * selectedPlan['Rate Output'] : 0;

  const searchInputRef = useRef(null);
  const quantityInputRef = useRef(null);

  const handleAddGenAIAgents = () => {
    const description = `AI Agent model: ${selectedPlan?.Description || ''}.`;
    const features = `Includes ${
      inputTokens >= 1000000
        ? `${(inputTokens / 1000000).toLocaleString()} Trillion`
        : inputTokens >= 1000
          ? `${(inputTokens / 1000).toLocaleString()} Billion`
          : `${inputTokens.toLocaleString()} Million`
    } input tokens and ${
      outputTokens >= 1000000
        ? `${(outputTokens / 1000000).toLocaleString()} Trillion`
        : outputTokens >= 1000
          ? `${(outputTokens / 1000).toLocaleString()} Billion`
          : `${outputTokens.toLocaleString()} Million`
    } output tokens per month.`;
    const priceMonthly = inputTokenCost + outputTokenCost;

    addQuoteItem({
      serviceType: 'GenAI',
      name: name,
      description: description,
      features: features,
      priceMonthly: formatPrice(priceMonthly),
      quantity: quantity,
      totalPrice: formatPrice(priceMonthly * quantity),
    });
    closeModal();
  };

  const handleAddGenKnowledgeBases = () => {
    const description = `Knowledge Base embeddings model: ${selectedPlan?.Description || ''}.`;
    const features = `Includes ${
      inputTokens >= 1000000
        ? `${(inputTokens / 1000000).toLocaleString()} Trillion`
        : inputTokens >= 1000
          ? `${(inputTokens / 1000).toLocaleString()} Billion`
          : `${inputTokens.toLocaleString()} Million`
    } tokens per month.`;
    const priceMonthly = inputTokenCost;

    addQuoteItem({
      serviceType: 'GenAI',
      name: name,
      description: description,
      features: features,
      priceMonthly: formatPrice(priceMonthly),
      quantity: quantity,
      totalPrice: formatPrice(priceMonthly * quantity),
    });
    closeModal();
  };

  const handleAddGenGuardRails = () => {
    const description = `Guardrail: ${selectedPlan?.Description || ''}.`;
    const features = `Includes ${
      inputTokens >= 1000000
        ? `${(inputTokens / 1000000).toLocaleString()} Trillion`
        : inputTokens >= 1000
          ? `${(inputTokens / 1000).toLocaleString()} Billion`
          : `${inputTokens.toLocaleString()} Million`
    } tokens per month.`;
    const priceMonthly = inputTokenCost;

    addQuoteItem({
      serviceType: 'GenAI',
      name: name,
      description: description,
      features: features,
      priceMonthly: formatPrice(priceMonthly),
      quantity: quantity,
      totalPrice: formatPrice(priceMonthly * quantity),
    });
    closeModal();
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setShowDropdown(false); // Close dropdown on tab switch
    setSelectedPlan(null); // Reset selected plan on tab switch
    setInputTokens(''); // Reset input tokens
    setOutputTokens(''); // Reset output tokens
    setQuantity(1); // Reset quantity
  };

  const filteredGenAIPlans = plansGenAI.filter(plan => {
    const regex = new RegExp(searchTerm.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    if (activeTab === 'Agents') {
      return plan.Subservice === 'Agent' && regex.test(plan.Description);
    } else if (activeTab === 'Knowledge Bases') {
      return plan.Subservice === 'Embeddings' && regex.test(plan.Description);
    }
    else if (activeTab === 'Guardrails') {
      return plan.Subservice === 'Guardrails' && regex.test(plan.Description);
    }
    return false;
  });

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setShowDropdown(false);
    setSearchTerm('');
    setQuantity(1);
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 1 && Number(value) <= 1000)) {
      setQuantity(value);
    }
  };

  const handleQuantityBlur = () => {
    if (quantity === '' || quantity < 1) {
      setQuantity(1);
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  };

  return (
    <div id="modal" className="modal genai-modal">
      <div id="modal-content" className="modal-content">
        <span id="close" className="close" onClick={closeModal}>&times;</span>
        <div id="modal-header" className="modal-header flex flex-between flex-center">
          <div>
            <h1>Add GenAI</h1>
          </div>
          <div id="modal-item-name" className="modal-item-name">
            <input
              type="text"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter item name or description"
              maxLength="50"
            />
          </div>
        </div>
        <div id="modal-tabs" className="modal-tabs">
          <button
            className={`tab-button ${activeTab === 'Agents' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Agents')}
          >
            Agents
          </button>
          <button
            className={`tab-button ${activeTab === 'Knowledge Bases' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Knowledge Bases')}
          >
            Knowledge Bases
          </button>
          <button
            className={`tab-button ${activeTab === 'Guardrails' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Guardrails')}
          >
            Guardrails
          </button>
        </div>

        <div id="genai-modal-body" className="modal-body">
          {activeTab === 'Agents' && (
            <div>
              <h5>SELECT AGENT MODEL</h5>
              <div id="search-container" className="search-container">
                <input
                  ref={searchInputRef}
                  type="text"
                  className="input"
                  placeholder="Search plans or click to view all"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setShowDropdown(true);
                  }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
                {showDropdown && filteredGenAIPlans.length > 0 && (
                  <ul id="dropdown-genai" className="dropdown">
                    {filteredGenAIPlans.map((plan, index) => (
                      <li key={index} onClick={() => handlePlanSelect(plan)} className="flex font-darkGray">
                        <div className="dropdown-plans-description">{plan.Description}</div>
                        <div id="price" className="dropdown-plans-rate-input">
                          <span className="dollar">$</span>
                          <span>{formatPrice(plan['Rate Input'])}</span>
                          <span className="period">/1M input</span>
                        </div>
                        <div id="price" className="dropdown-plans-rate-output">
                          <span className="dollar">$</span>
                          <span>{formatPrice(plan['Rate Output'])}</span>
                          <span className="period">/1M output</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {showDropdown && filteredGenAIPlans.length === 0 && (
                  <ul id="dropdown" className="dropdown">
                    <li>No plans found</li>
                  </ul>
                )}
              </div>
              {selectedPlan && (
                <div>
                  <div id="card" className="card">
                    <div id="card-header" className="card-header flex flex-between">
                      <div>
                        <h5>SELECTED MODEL</h5>
                      </div>
                      <div id="price" className="price flex-column">
                        <div>
                          <span className="dollar">$</span>
                          <span className="cost">{formatPrice(selectedPlan['Rate Input'])}</span>
                          <span className="period">/1M input</span>
                        </div>
                        <div>
                          <span className="dollar">$</span>
                          <span className="cost">{formatPrice(selectedPlan['Rate Output'])}</span>
                          <span className="period">/1M output</span>
                        </div>
                      </div>
                    </div>
                    <div id="card-body" className="card-body">
                      <p>{selectedPlan.Description}</p>
                    </div>
                  </div>
                  <h5>SELECT INPUT TOKENS</h5>
                  <div id="calculation-container" className="calculation-container">
                    <div id="calculation-container-price" className="calculation-container-price flex">
                      <div className="price">
                        <span className="dollar dollar-large">$</span>
                        <span className="cost cost-large">{inputTokenCost ? formatPrice(inputTokenCost) : '0'}</span>
                        <span className="period period-large">/mo</span>
                      </div>
                      <div>
                        <span>
                          {inputTokens === "" 
                            ? "_ Million tokens"
                            : inputTokens >= 1000000
                              ? `${(inputTokens / 1000000).toFixed(2).replace(/\.0+$/, '')} Trillion tokens`
                              : inputTokens >= 1000
                                ? `${(inputTokens / 1000).toFixed(2).replace(/\.0+$/, '')} Billion tokens`
                                : `${inputTokens} Million tokens`}
                        </span>
                      </div>
                    </div>
                    <div id="calculation-container-input" className="calculation-container-input">
                      <input 
                        type="text" 
                        spellCheck="false" 
                        required 
                        placeholder="Enter input tokens"  
                        value={inputTokens}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) { // Only allow digits
                            const numValue = parseInt(value, 10);
                            if (!isNaN(numValue) && numValue >= 0 && numValue <= 1000000) {
                              setInputTokens(value);
                            } else if (value === '') {
                              setInputTokens('');
                            }
                          }
                        }}
                      />
                      <div id="floatlabel-price" className="floatlabel-price">
                        <label>Enter input tokens in Millions</label>
                      </div>
                    </div>
                  </div>

                  <h5>SELECT OUTPUT TOKENS</h5>
                  <div id="calculation-container-output" className="calculation-container">
                    <div id="calculation-container-price" className="calculation-container-price flex">
                      <div className="price">
                        <span className="dollar dollar-large">$</span>
                        <span className="cost cost-large">{outputTokenCost ? formatPrice(outputTokenCost) : '0'}</span>
                        <span className="period period-large">/mo</span>
                      </div>
                      <div>
                        <span>
                          {outputTokens === "" 
                            ? "_ Million tokens"
                            : outputTokens >= 1000000
                              ? `${(outputTokens / 1000000).toFixed(2).replace(/\.0+$/, '')} Trillion tokens`
                              : outputTokens >= 1000
                                ? `${(outputTokens / 1000).toFixed(2).replace(/\.0+$/, '')} Billion tokens`
                                : `${outputTokens} Million tokens`}
                        </span>
                      </div>
                    </div>
                    <div id="calculation-container-input" className="calculation-container-input">
                      <input 
                        type="text" 
                        spellCheck="false" 
                        required 
                        placeholder="Enter output tokens"  
                        value={outputTokens}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) { // Only allow digits
                            const numValue = parseInt(value, 10);
                            if (!isNaN(numValue) && numValue >= 0 && numValue <= 1000000) {
                              setOutputTokens(value);
                            } else if (value === '') {
                              setOutputTokens('');
                            }
                          }
                        }}
                      />
                      <div id="floatlabel-price" className="floatlabel-price">
                        <label>Enter output tokens in Millions</label>
                      </div>
                    </div>
                  </div>

                  <h5>SELECT QUANTITY</h5>
                  <input
                    ref={quantityInputRef}
                    type="number"
                    className="input"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleQuantityBlur}
                    min="1"
                    max="1000"
                  />
                </div>
              )}
            </div>
          )}
          {activeTab === 'Knowledge Bases' && (
            <div>
            <h5>SELECT EMBEDDINGS MODEL</h5>
            <div id="search-container" className="search-container">
              <input
                ref={searchInputRef}
                type="text"
                className="input"
                placeholder="Search plans or click to view all"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setShowDropdown(true);
                }}
                onClick={() => setShowDropdown(!showDropdown)}
              />
              {showDropdown && filteredGenAIPlans.length > 0 && (
                <ul id="dropdown-genai" className="dropdown">
                  {filteredGenAIPlans.map((plan, index) => (
                    <li key={index} onClick={() => handlePlanSelect(plan)} className="flex font-darkGray">
                      <div className="dropdown-plans-description">{plan.Description}</div>
                      <div id="price" className="dropdown-plans-rate-input">
                      </div>
                      <div id="price" className="dropdown-plans-rate-output">
                        <span className="dollar">$</span>
                        <span>{formatPrice(plan['Rate Input'])}</span>
                        <span className="period">/1M tokens</span>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {showDropdown && filteredGenAIPlans.length === 0 && (
                <ul id="dropdown" className="dropdown">
                  <li>No plans found</li>
                </ul>
              )}
            </div>
            {selectedPlan && (
              <div>
                <div id="card" className="card">
                  <div id="card-header" className="card-header flex flex-between">
                    <div>
                      <h5>SELECTED MODEL</h5>
                    </div>
                    <div id="price" className="price flex-column">
                      <div>
                        <span className="dollar">$</span>
                        <span className="cost">{formatPrice(selectedPlan['Rate Input'])}</span>
                        <span className="period">/1M tokens</span>
                      </div>
                    </div>
                  </div>
                  <div id="card-body" className="card-body">
                    <p>{selectedPlan.Description}</p>
                  </div>
                </div>
                <h5>SELECT INDEXING TOKENS</h5>
                <div id="calculation-container" className="calculation-container">
                  <div id="calculation-container-price" className="calculation-container-price flex">
                    <div className="price">
                      <span className="dollar dollar-large">$</span>
                      <span className="cost cost-large">{inputTokenCost ? formatPrice(inputTokenCost) : '0'}</span>
                      <span className="period period-large">/mo</span>
                    </div>
                    <div>
                      <span>
                        {inputTokens === "" 
                          ? "_ Million tokens"
                          : inputTokens >= 1000000
                            ? `${(inputTokens / 1000000).toFixed(2).replace(/\.0+$/, '')} Trillion tokens`
                            : inputTokens >= 1000
                              ? `${(inputTokens / 1000).toFixed(2).replace(/\.0+$/, '')} Billion tokens`
                              : `${inputTokens} Million tokens`}
                      </span>
                    </div>
                  </div>
                  <div id="calculation-container-input" className="calculation-container-input">
                    <input 
                      type="text" 
                      spellCheck="false" 
                      required 
                      placeholder="Enter indexing tokens"  
                      value={inputTokens}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) { // Only allow digits
                          const numValue = parseInt(value, 10);
                          if (!isNaN(numValue) && numValue >= 0 && numValue <= 1000000) {
                            setInputTokens(value);
                          } else if (value === '') {
                            setInputTokens('');
                          }
                        }
                      }}
                    />
                    <div id="floatlabel-price" className="floatlabel-price">
                      <label>Enter indexing tokens in Millions</label>
                    </div>
                  </div>
                </div>

                <h5>SELECT QUANTITY</h5>
                  <input
                    ref={quantityInputRef}
                    type="number"
                    className="input"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleQuantityBlur}
                    min="1"
                    max="1000"
                  />
              </div>
            )}
          </div>            
          )}
          {activeTab === 'Guardrails' && (
            <div>
              <h5>SELECT GUARDRAIL</h5>
              <div id="search-container" className="search-container">
                <input
                  ref={searchInputRef}
                  type="text"
                  className="input"
                  placeholder="Search plans or click to view all"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setShowDropdown(true);
                  }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
                {showDropdown && filteredGenAIPlans.length > 0 && (
                  <ul id="dropdown-genai" className="dropdown">
                    {filteredGenAIPlans.map((plan, index) => (
                      <li key={index} onClick={() => handlePlanSelect(plan)} className="flex font-darkGray">
                        <div className="dropdown-plans-description">{plan.Description}</div>
                        <div id="price" className="dropdown-plans-rate-input">
                        </div>
                        <div id="price" className="dropdown-plans-rate-output">
                          <span className="dollar">$</span>
                          <span>{formatPrice(plan['Rate Input'])}</span>
                          <span className="period">/1M tokens</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {showDropdown && filteredGenAIPlans.length === 0 && (
                  <ul id="dropdown" className="dropdown">
                    <li>No guardrails found</li>
                  </ul>
                )}
              </div>
              {selectedPlan && (
                <div>
                  <div id="card" className="card">
                    <div id="card-header" className="card-header flex flex-between">
                      <div>
                        <h5>SELECTED GUARDRAIL</h5>
                      </div>
                      <div id="price" className="price flex-column">
                        <div>
                          <span className="dollar">$</span>
                          <span className="cost">{formatPrice(selectedPlan['Rate Input'])}</span>
                          <span className="period">/1M tokens</span>
                        </div>
                      </div>
                    </div>
                    <div id="card-body" className="card-body">
                      <p>{selectedPlan.Description}</p>
                    </div>
                  </div>
                  <h5>SELECT TOKENS</h5>
                  <div id="calculation-container" className="calculation-container">
                    <div id="calculation-container-price" className="calculation-container-price flex">
                      <div className="price">
                        <span className="dollar dollar-large">$</span>
                        <span className="cost cost-large">{inputTokenCost ? formatPrice(inputTokenCost) : '0'}</span>
                        <span className="period period-large">/mo</span>
                      </div>
                      <div>
                        <span>
                          {inputTokens === "" 
                            ? "_ Million tokens"
                            : inputTokens >= 1000000
                              ? `${(inputTokens / 1000000).toFixed(2).replace(/\.0+$/, '')} Trillion tokens`
                              : inputTokens >= 1000
                                ? `${(inputTokens / 1000).toFixed(2).replace(/\.0+$/, '')} Billion tokens`
                                : `${inputTokens} Million tokens`}
                        </span>
                      </div>
                    </div>
                    <div id="calculation-container-input" className="calculation-container-input">
                      <input 
                        type="text" 
                        spellCheck="false" 
                        required 
                        placeholder="Enter tokens"  
                        value={inputTokens}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) { // Only allow digits
                            const numValue = parseInt(value, 10);
                            if (!isNaN(numValue) && numValue >= 0 && numValue <= 1000000) {
                              setInputTokens(value);
                            } else if (value === '') {
                              setInputTokens('');
                            }
                          }
                        }}
                      />
                      <div id="floatlabel-price" className="floatlabel-price">
                        <label>Enter tokens in Millions</label>
                      </div>
                    </div>
                  </div>

                  <h5>SELECT QUANTITY</h5>
                  <input
                    ref={quantityInputRef}
                    type="number"
                    className="input"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleQuantityBlur}
                    min="1"
                    max="1000"
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div id="modal-footer" className="modal-footer flex flex-between flex-center">
          <div id="action-button" className="action-button">
            {activeTab === 'Agents' && (
              <button
                className="button-blue"
                onClick={handleAddGenAIAgents}
                disabled={!selectedPlan || !(Number(inputTokens) > 0 && Number(outputTokens) > 0)}
              >
                Add AI Agents
              </button>
            )}
            {activeTab === 'Knowledge Bases' && (
              <button className="button-blue" 
              onClick={handleAddGenKnowledgeBases}
              disabled={!selectedPlan || !(Number(inputTokens) > 0)}              
              >
                Add Knowledge Bases
              </button>
            )}
            {activeTab === 'Guardrails' && (
              <button className="button-blue" 
              onClick={handleAddGenGuardRails}
              disabled={!selectedPlan || !(Number(inputTokens) > 0)}              
              >
                Add Guardrails
              </button>
            )}
          </div>
          <div id="price" className="price">
            <span className="dollar dollar-large">$</span>
            <span className="cost cost-large">
              {formatPrice(
                activeTab === 'Agents' && selectedPlan
                  ? (inputTokens > 0 ? inputTokens * selectedPlan['Rate Input'] * quantity : 0) +
                    (outputTokens > 0 ? outputTokens * selectedPlan['Rate Output'] * quantity : 0)
                  : activeTab === 'Knowledge Bases' && selectedPlan
                    ? (inputTokens > 0 ? inputTokens * selectedPlan['Rate Input'] * quantity : 0)
                    : activeTab === 'Guardrails' && selectedPlan
                      ? (inputTokens > 0 ? inputTokens * selectedPlan['Rate Input'] * quantity : 0)
                      : 0
              )}
            </span>
            <span className="period period-large">/mo</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenAIModal;
