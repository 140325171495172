// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.docr-modal .modal-radio-buttons {
  align-items: unset;
  width: 60%;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.docr-modal .calculation-container {
  width: 14rem;
}`, "",{"version":3,"sources":["webpack://./src/modals/DOCRModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,2BAAsB;EAAtB,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".docr-modal .modal-radio-buttons {\n  align-items: unset;\n  width: 60%;\n  min-width: fit-content;\n}\n\n.docr-modal .calculation-container {\n  width: 14rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
