// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#genai-modal-body .dropdown-plans-description {
  width: 30rem;
}

#genai-modal-body .dropdown-plans-rate-input {
  line-height: 1rem;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-right: auto;
}

#genai-modal-body .dropdown-plans-rate-output {
  line-height: 1rem;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.genai-modal .calculation-container {
  width: 16rem;
}
`, "",{"version":3,"sources":["webpack://./src/modals/GenAIModal.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,2BAAsB;EAAtB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,2BAAsB;EAAtB,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd","sourcesContent":["#genai-modal-body .dropdown-plans-description {\n  width: 30rem;\n}\n\n#genai-modal-body .dropdown-plans-rate-input {\n  line-height: 1rem;\n  min-width: fit-content;\n  margin-right: auto;\n}\n\n#genai-modal-body .dropdown-plans-rate-output {\n  line-height: 1rem;\n  min-width: fit-content;\n}\n\n.genai-modal .calculation-container {\n  width: 16rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
