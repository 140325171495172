import React, { useState } from 'react';
import './SpacesModal.css';

function SpacesModal({ closeModal, addQuoteItem, formatPrice }) {
  const [name, setName] = useState('');
  const [storage, setStorage] = useState(''); 
  const [dataTransfer, setDataTransfer] = useState(''); 


  // Included storage and transfer
  const includedStorage = 250;
  const includedDataTransfer = 1024;

  // Conversion factor from GiB to GB
  const conversionFactorGiBtoGB = 1.0737;

  // Conversion factor from TiB to TB
  const conversionFactorTiBtoTB = 1.0995;

  // Conversion factor from PiB to PB
  const conversionFactorPiBtoPB = 1.1259;

  // Calculate costs 
  const Storage = parseInt(storage, 10); // GiBs of storage
  const DataTransfer = parseInt(dataTransfer, 10); // TiBs of transfer

  // Calculate excess storage beyond included
  const excessStorage = Storage > includedStorage ? (Storage - includedStorage) : 0;
  const storageCost = excessStorage > 0 ? excessStorage * 0.02 : 0;

  // Calculate excess data transfer beyond included
  const excessDataTransfer = DataTransfer > includedDataTransfer ? DataTransfer - includedDataTransfer : 0;
  const dataTransferCost = excessDataTransfer > 0 ? excessDataTransfer * 0.01 : 0;

  const basePrice = 5; // Base price 
  const totalPrice = basePrice + storageCost + dataTransferCost;

  const handleAddSpaces = () => {
    const formattedStorage = `${(includedStorage + excessStorage).toLocaleString()} GiB (${(includedStorage + excessStorage * conversionFactorGiBtoGB).toLocaleString(undefined, {maximumFractionDigits: 0})} GB) storage`;
    const formattedDataTransfer = `${(includedDataTransfer + excessDataTransfer).toLocaleString()} GiB (${(includedDataTransfer + excessDataTransfer * conversionFactorTiBtoTB).toLocaleString(undefined, {maximumFractionDigits: 0})} GB) data transfer per month.`;
      
    addQuoteItem({
      serviceType: 'Spaces',
      name: name,
      description: `Object storage with ${formattedStorage} and ${formattedDataTransfer}`,
      features: '',
      priceMonthly: formatPrice(totalPrice),
      quantity: 1,
      totalPrice: formatPrice(totalPrice)
    });
    closeModal();
  };


  return (
    <div id="modal" className="modal spaces-modal">
      <div id="modal-content" className="modal-content">
        <span id="close" className="close" onClick={closeModal}>&times;</span>
        <div id="modal-header" className="modal-header flex flex-between flex-center">
          <div>
            <h1>
              Add Spaces
            </h1>
          </div>
          <div id="modal-item-name" className="modal-item-name">
            <input
              type="text"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter item name or description"
              maxLength="50"
            />
          </div>
        </div>
        <div id="modal-body" className="modal-body">
          <div id="alert-info" className="flex flex-center">
            <svg className="alert-info" aria-hidden="true">
              <use href="#_sprite-alert-info"></use>
            </svg> 
            <div className="period font-blueGray font-weight-400">           
              Spaces cost $5 and include 250 GiB of data storage and 1,024 GiB of data transfer per month.
            </div>
          </div>
          <h5>SELECT DATA STORAGE SIZE</h5>
          <div id="calculation-container" className="calculation-container">
            <div id="calculation-container-price" className="calculation-container-price flex">
              <div className="price">
                <span className="dollar dollar-large">$</span>
                <span className="cost cost-large">{storageCost ? formatPrice(storageCost) : '0'}</span>
                <span className="period period-large">/mo</span>
              </div>
              <div>
                <span>
                  {includedStorage + excessStorage >= 1024 * 1024
                    ? `${((includedStorage + excessStorage) / 1024 / 1024).toFixed(2).replace(/\.0+$/, '')} PiB (${((includedStorage + excessStorage) / 1024 / 1024 * conversionFactorPiBtoPB).toFixed(2).replace(/\.0+$/, '')} PB) storage`
                    : includedStorage + excessStorage >= 1024
                      ? `${((includedStorage + excessStorage) / 1024).toFixed(2).replace(/\.0+$/, '')} TiB (${((includedStorage + excessStorage) / 1024 * conversionFactorTiBtoTB).toFixed(2).replace(/\.0+$/, '')} TB) storage`
                      : `${(includedStorage + excessStorage)} GiB (${(((includedStorage + excessStorage) * conversionFactorGiBtoGB).toFixed(0))} GB) storage`}
                </span>
              </div>
            </div>
            <div id="calculation-container-input" className="calculation-container-input">
              <input 
                type="text" 
                spellCheck="false" 
                required 
                placeholder="Enter storage"  
                value={storage}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) { // Only allow digits
                    const numValue = parseInt(value, 10);
                    if (!isNaN(numValue) && numValue >= 0 && numValue <= 10485760) {
                      setStorage(value);
                    } else if (value === '') {
                      setStorage('');
                    }
                  }
                }}
              />
              <div id="floatlabel-price" className="floatlabel-price">
                <label>Enter storage in GiB</label>
              </div>
            </div>
          </div>
          <h5>SELECT DATA TRANSFER SIZE</h5>
          <div id="calculation-container" className="calculation-container">
            <div id="calculation-container-price" className="calculation-container-price flex">
              <div className="price">
                <span className="dollar dollar-large">$</span>
                <span className="cost cost-large">{dataTransferCost ? formatPrice(dataTransferCost) : '0'}</span>
                <span className="period period-large">/mo</span>
              </div>
              <div>
                <span>
                  {includedDataTransfer + excessDataTransfer > 1024 * 1024
                    ? `${((includedDataTransfer + excessDataTransfer) / 1024 / 1024).toFixed(2).replace(/\.0+$/, '')} PiB (${((includedDataTransfer + excessDataTransfer) / 1024 / 1024 * conversionFactorPiBtoPB).toFixed(2).replace(/\.0+$/, '')} PB) transfer`
                      : includedStorage + excessStorage >= 1024
                      ? `${((includedDataTransfer + excessDataTransfer) / 1024).toFixed(2).replace(/\.0+$/, '')} TiB (${((includedDataTransfer + excessDataTransfer) / 1024 * conversionFactorTiBtoTB).toFixed(2).replace(/\.0+$/, '')} TB) transfer`
                      : `${(includedDataTransfer + excessDataTransfer)} TiB (${((includedDataTransfer + excessDataTransfer) * conversionFactorTiBtoTB).toFixed(0)} TB) transfer`}
                </span>
              </div>
            </div>
            <div id="calculation-container-input" className="calculation-container-input">
              <input 
                type="text" 
                spellCheck="false" 
                required 
                placeholder="Enter transfer per month"  
                value={dataTransfer}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) { // Only allow digits
                    const numValue = parseInt(value, 10);
                    if (!isNaN(numValue) && numValue >= 0 && numValue <= 104857600) {
                      setDataTransfer(value);
                    } else if (value === '') {
                      setDataTransfer('');
                    }
                  }
                }}
              />
              <div id="floatlabel-price" className="floatlabel-price">
                <label>Enter transfer in GiB</label>
              </div>
            </div>
          </div>
        </div>
        <div id="modal-footer" className="modal-footer flex flex-between flex-center">
          <div id="action-button" className="action-button">
            <button
              className="button-blue"
              onClick={handleAddSpaces}
            >
              <span>Add Spaces</span>
            </button>
          </div>
          <div id="price" className="price">
            <span className="dollar dollar-large">$</span>
            <span className="cost cost-large">{formatPrice((basePrice + storageCost + dataTransferCost))}</span>
            <span className="period period-large">/mo</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpacesModal;