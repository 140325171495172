import React, { useState } from 'react';
import './SupportModal.css';

const price = {
  developer: 24.00,
  standard: 99.00,
  premium: 999.00
};

function SupportModal({ closeModal, addQuoteItem, formatPrice }) {
  const [supportOption, setSupportOption] = useState('developer');
  const [name, setName] = useState('');

  // Calculate the total price
  const calculateTotalPrice = () => {
    return formatPrice(price[supportOption] || 0);
  };

  // Handle adding the selected plan to the quote
  const handleAddSupport = () => {
    const supportDetails = {
      developer: {
        description: 'Developer support plan with response time within 8 hours.',
        features: '',
      },
      standard: {
        description: 'Standard support plan with response time within 2 hours.',
        features: '',
      },
      premium: {
        description: 'Premium Support Plan with 30-minute response time and access to a dedicated Slack channel.',
        features: '',
      }
    };

    const selectedSupport = supportDetails[supportOption] || {};
    const totalPrice = calculateTotalPrice();

    addQuoteItem({
      serviceType: 'Support Plans',
      name: name,
      description: selectedSupport.description,
      features: selectedSupport.features,
      priceMonthly: totalPrice,
      quantity: 1,
      totalPrice
    });
    closeModal(); 
  };

  return (
    <div id="modal" className="modal support-modal">
      <div id="modal-content" className="modal-content">
        <span id="close" className="close" onClick={closeModal}>&times;</span>
        <div id="modal-header" className="modal-header flex flex-between flex-center">
          <div>
            <h1>Add Support Plans</h1>
          </div>
          <div id="modal-item-name" className="modal-item-name">
            <input
              type="text"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter item name or description"
              maxLength="50"
            />
          </div>
        </div>
        <div id="modal-body" className="modal-body">
          <h5>SELECT SUPPORT PLAN</h5>
          <div id="modal-radio-buttons" className="modal-radio-buttons flex flex-column gap-dot5">
            <div
              className={`radio-button ${supportOption === 'developer' ? 'radio-button-active' : ''}`}
              onClick={() => setSupportOption('developer')}
            >
              <div className="flex flex-between flex-center">
                <div className="font-weight-600">Developer</div>
                <div id="price" class="price">
                    <span class="dollar">$</span>
                    <span class="cost">{formatPrice(price.developer)}</span>
                    <span class="period">/mo</span>
                </div>
              </div>
              <div className="font-blueGray font-size-dot8 font-weight-400" style={{ marginTop: '.1rem' }}>Response time within 8 hours.</div>
            </div>
            <div
              className={`radio-button ${supportOption === 'standard' ? 'radio-button-active' : ''}`}
              onClick={() => setSupportOption('standard')}
            >
              <div className="flex flex-between flex-center">
                <div className="font-weight-600">Standard</div>
                <div id="price" class="price">
                  <span class="dollar">$</span>
                  <span class="cost">{formatPrice(price.standard)}</span>
                  <span class="period">/mo</span>
                </div>
              </div>
              <div className="font-blueGray font-size-dot8 font-weight-400" style={{ marginTop: '.1rem' }}>Response time within 2 hours.</div>
            </div>
            <div
              className={`radio-button ${supportOption === 'premium' ? 'radio-button-active' : ''}`}
              onClick={() => setSupportOption('premium')}
            >
              <div className="flex flex-between flex-center">
                <div className="font-weight-600">Premium</div>
                <div id="price" class="price">
                  <span class="dollar">$</span>
                  <span class="cost">{formatPrice(price.premium)}</span>
                  <span class="period">/mo</span>
                </div>
              </div>
              <div className="font-blueGray font-size-dot8 font-weight-400" style={{ marginTop: '.1rem' }}>Response time within 30 minutes.</div>
            </div>
          </div>
        </div>
        <div id="modal-footer" className="modal-footer flex flex-between flex-center">
          <div id="action-button" className="action-button">
            <button className="button-blue" onClick={handleAddSupport}>
              <span>Add Support Plan</span>
            </button>
          </div>
          <div id="price" className="price">
            <span className="dollar dollar-large">$</span>
            <span className="cost cost-large">
              {calculateTotalPrice()}
            </span>
            <span className="period period-large">/mo</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportModal;