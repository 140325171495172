import React, { useState, useRef, useEffect } from 'react';
import './DropletsModal.css';

function DropletsModal({ closeModal, addQuoteItem, formatPrice, plansDroplets }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [backupOption, setBackupOption] = useState('none'); // Set default to "No Backups"
  const [name, setName] = useState('');
  const searchInputRef = useRef(null);
  const quantityInputRef = useRef(null);
  const [overage, setOverage] = useState('');
  const [gpuHours, setGpuHours] = useState(1);
  const [activeTab, setActiveTab] = useState('Droplets');
  const [billingOption, setBillingOption] = useState('on-demand'); // Set default to "On-demand"
  const [negotiatedPrice, setNegotiatedPrice] = useState('');
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSelectedPlan(null);
    setShowDropdown(false);
    setGpuHours(1);
  };

  const overagePrice = 10.24;
  const minGpuHours = 1;
  const maxGpuHours = 744;

  // Conversion factor from GiB to GB
  const conversionFactorGiBtoGB = 1.0737;

  // Conversion factor from TiB to TB
  const conversionFactorTiBtoTB = 1.0995;

  // Conversion factor from PiB to PB
  const conversionFactorPiBtoPB = 1.1259;

  // Focus and select the quantity input when a plan is selected
  useEffect(() => {
    if (selectedPlan) {
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  }, [selectedPlan]);

  // Filter Droplet plans based on the search term by applying regex across various plan attributes
  const filteredPlans = plansDroplets.filter(plan => {
    if (plan.GPU) {
      return false;
    }

    // Create a regex from the search term, escaping special characters to prevent regex errors
    const regex = new RegExp(searchTerm.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    
    // Test regex against plan fields, converting numeric fields to string with descriptive text
    return (
      regex.test(plan.Description) ||  // Check plan description
      regex.test(plan.Slug) ||  // Check plan slug or identifier
      regex.test(`${plan.VCPUs} vCPU`) ||  // Check virtual CPUs with 'vCPU' appended
      regex.test(`${plan.Memory} GiB Memory`) ||  // Check memory size with 'GiB Memory' appended
      regex.test(`${plan.Disk} GiB Disk`) ||  // Check disk size with 'GiB Disk' appended
      regex.test(plan.Nvme) ||  // Check if NVMe is supported
      regex.test(`${plan.Network} Gbps Network`) ||  // Check network speed with 'Gbps Network' appended
      regex.test(`${plan.Transfer} GiB Transfer`) ||  // Check data transfer limit with 'GiB Transfer' appended
      regex.test(`$${plan['Price Monthly']} Monthly`)  // Check monthly price with 'Monthly' appended
    );
  });

  // Filter GPU Droplet plans based on the search term by applying regex across various plan attributes
  const filteredGpuPlans = plansDroplets.filter(plan => {
    if (!plan.GPU) {
      return false;
    }

    // Create a regex from the search term, escaping special characters to prevent regex errors
    const regex = new RegExp(searchTerm.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    
    // Test regex against plan fields, converting numeric fields to string with descriptive text
    return (
      regex.test(plan.Description) ||  // Check plan description
      regex.test(plan.Slug) ||  // Check plan slug or identifier
      regex.test(`${plan.VCPUs} vCPU`) ||  // Check virtual CPUs with 'vCPU' appended
      regex.test(`${plan.Memory} GB Memory`) ||  // Check memory size with 'GB Memory' appended
      regex.test(`${plan.Disk} GiB Disk`) ||  // Check disk size with 'GiB Disk' appended
      regex.test(plan.Nvme) ||  // Check if NVMe is supported
      regex.test(`${plan.Network} Gbps Network`) ||  // Check network speed with 'Gbps Network' appended
      regex.test(`$${plan['Price Hourly']} Hourly`)  // Check monthly price with 'Hourly' appended
    );
  });

  // Handle plan selection
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setShowDropdown(false);
    setSearchTerm(''); // Clear the search term after selection
    setQuantity(1); // Set default quantity to 1
    setGpuHours(1); // Set default GPU hours to 1
    setBackupOption('none'); // Reset backup option
    setBillingOption('on-demand'); // Set billing option to "on-demand"
  };

  // Handle quantity change
  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 1 && Number(value) <= 10000)) {
      setQuantity(value);
    }
  };

  // Handle quantity blur (when input loses focus)
  const handleQuantityBlur = () => {
    if (quantity === '' || quantity < 1) {
      setQuantity(1);
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  };

  // Calculate the total Droplet price
  const calculateTotalDropletPrice = () => {
    if (!selectedPlan) return 0;
    let price = parseFloat(selectedPlan['Price Monthly']);
    if (backupOption === 'daily') {
      price *= 1.3; // Add 30% for daily backups
    } else if (backupOption === 'weekly') {
      price *= 1.2; // Add 20% for weekly backups
    }
    return formatPrice(price * (quantity || 1));
  };

  const totalDropletPrice = calculateTotalDropletPrice();

  // Calculate the total GPU Droplet price
  const calculateTotalGpuDropletPrice = () => {
    if (!selectedPlan) return 0;
    let price = billingOption === 'contracted' && negotiatedPrice ? parseFloat(negotiatedPrice) : parseFloat(selectedPlan['Price Hourly']);
    price *= gpuHours;
    price *= selectedPlan.GPU; // Multiply by the number of GPUs
    return formatPrice(price * (quantity || 1));
  };

  const totalGpuDropletPrice = calculateTotalGpuDropletPrice();  

	// Function to increment the GPU hours by 1, capped at the maximum limit
	const handleGpuHoursIncrement = () => {
		setGpuHours(gpuHours+1);
		calculateTotalGpuDropletPrice();
	};

	// Function to decrement the GPU hours by 1, capped at the minimum limit
	const handleGpuHoursDecrement = () => {
		setGpuHours(gpuHours-1);
		calculateTotalGpuDropletPrice();
	};

  const handleBillingOptionChange = (option) => {
    setBillingOption(option);
    if (option === 'contracted') {
      setGpuHours(744); // Set hours to 744 for contracted option
      setNegotiatedPrice(selectedPlan ? selectedPlan['Price Hourly'] : ''); // Set default negotiated price
    } else if (option === 'on-demand') {
      setGpuHours(1); // Reset hours to 1 for on-demand option
      setNegotiatedPrice(''); // Reset negotiated price
    }
  };

  // Handle adding the selected Droplet plan to the quote
  const handleAddDroplets = () => {
    if (selectedPlan) {
      let description = `${selectedPlan.Description} - ${selectedPlan.VCPUs} vCPU / ${selectedPlan.Memory} GiB Memory / ${selectedPlan.Disk} GiB Disk / ${selectedPlan.Transfer} GiB Egress.`;
      let features = '';
      if (backupOption === 'daily') {
        features = 'Daily backups. ';
      } else if (backupOption === 'weekly') {
        features = 'Weekly backups. ';
      } 
      if (selectedPlan.Nvme === 'nvme') {
        features += 'NVMe guaranteed.';
      }
      if (selectedPlan.Network === '10') {
        features += ' 10 Gbps network.';
      }

      addQuoteItem({
        serviceType: 'Droplets',
        name: name, // Use the value from the new input field
        description: description,
        features: features,
        transfer: selectedPlan.Transfer * 1/1024,
        priceMonthly: formatPrice(calculateTotalDropletPrice().replace(/,/g, '') / quantity),
        quantity: quantity || 1,
        totalPrice: totalDropletPrice
      });
      closeModal(); // Close the modal after adding the quote item
    }
  };

  // Handle adding the selected GPU Droplet plan to the quote
  const handleAddGpuDroplets = () => {
    let billingType = billingOption === 'contracted' ? 'Contracted' : 'On-demand';  
    let description = `${selectedPlan.Description} - ${selectedPlan.VCPUs} vCPU / ${selectedPlan.Memory} GiB Memory / ${selectedPlan.Disk} GiB Boot Disk / ${selectedPlan.Transfer} GiB Egress.`;
    let features = `${billingType} GPU Droplet(s) with ${gpuHours} hour${(gpuHours % 10 === 1 && gpuHours % 100 !== 11) ? '' : 's'} of usage per month at $${negotiatedPrice || selectedPlan['Price Hourly']} per GPU.`;

    addQuoteItem({
      serviceType: 'Droplets',
      name: name, // Use the value from the new input field
      description: description,
      features: features,
      transfer: selectedPlan.Transfer * 1/1024,
      priceMonthly: formatPrice(calculateTotalGpuDropletPrice().replace(/,/g, '') / quantity),
      quantity: quantity || 1,
      totalPrice: totalGpuDropletPrice
    });
    closeModal(); // Close the modal after adding the quote item
  };

  const handleAddOverage = () => {
    const numOverage = parseInt(overage, 10);
    if (!isNaN(numOverage) && numOverage > 0 && numOverage <= 10240) {
      addQuoteItem({
        serviceType: 'Droplet and Kubernetes Egress Overage',
        name: name, // Use the value from the new input field
        description: `Overage of ${(numOverage).toLocaleString()} TiB (${(numOverage * conversionFactorTiBtoTB).toLocaleString(undefined, {maximumFractionDigits: 0})} TB).`,
        transfer: numOverage,
        priceMonthly: formatPrice(numOverage * overagePrice),
        quantity: 1,
        totalPrice: formatPrice(numOverage * overagePrice)
      });
      closeModal();
    }
  };  

  return (
    <div id="modal" className="modal">
      <div id="modal-content" className="modal-content droplets-modal">
        <span id="close" className="close" onClick={closeModal}>&times;</span>
        <div id="modal-header" className="modal-header flex flex-between flex-center">
          <div>
            <h1>Add Droplets</h1>
          </div>
          <div id="modal-item-name" className="modal-item-name">
            <input
              type="text"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter item name or description"
              maxLength="50" // Added maxLength attribute
            />
          </div>
        </div>
        <div id="modal-tabs" className="modal-tabs">
          <button
            className={`tab-button ${activeTab === 'Droplets' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Droplets')}
          >
            Droplets
          </button>
          <button
            className={`tab-button ${activeTab === 'GPU Droplets' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('GPU Droplets')}
          >
            GPU Droplets
          </button>
          <button
            className={`tab-button ${activeTab === 'Egress Overage' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Egress Overage')}
          >
            Egress Overage
          </button>
        </div>
        <div id="modal-body" className="modal-body">
          {activeTab === 'Droplets' && (
            <div>
              <h5>SELECT DROPLET PLAN</h5>
              <div id="search-container" className="search-container">
                <input
                  ref={searchInputRef}
                  type="text"
                  className="input"
                  placeholder="Search plans or click to view all"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setShowDropdown(true);
                  }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
                {showDropdown && filteredPlans.length > 0 && (
                  <ul id="dropdown" className="dropdown">
                    {filteredPlans.map((plan, index) => (
                      <li key={index} onClick={() => handlePlanSelect(plan)} className="flex font-darkGray ">
                        <div className="dropdown-plans-description">
                          {plan.Description}
                        </div>
                        <div className="dropdown-plans-specifications">
                          {plan.VCPUs} vCPU, {plan.Memory} GiB Memory, {plan.Disk} GiB Disk
                        </div>
                        <div id="price" className="price">
                          <span className="dollar">$</span>
                          <span>
                            {formatPrice(parseInt(plan['Price Monthly'], 10))}
                          </span>
                          <span className="period">/mo</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {showDropdown && filteredPlans.length === 0 && (
                  <ul id="dropdown" className="dropdown">
                    <li>No plans found</li>
                  </ul>
                )}
              </div>
              {selectedPlan && (
                <div>
                  <div id="card" className="card">
                    <div id="card-header" className="card-header flex flex-between flex-center">
                      <div>
                        <h5>SELECTED PLAN</h5>
                      </div>
                      <div id="price" className="price">
                        <span className="dollar">$</span>
                        <span className="cost">{formatPrice(calculateTotalDropletPrice().replace(/,/g, '') / quantity)}</span>
                        <span className="period">/mo</span>
                      </div>
                    </div>
                    <div id="card-body" className="card-body">
                      <p>{selectedPlan.Description}</p>
                      <p>{selectedPlan.VCPUs} vCPU / {selectedPlan.Memory} GiB Memory / {selectedPlan.Disk} GiB Disk / {selectedPlan.Transfer} GiB Egress</p>
                    </div>
                  </div>
                  <div>
                    <h5>SELECT BACKUPS</h5>
                    <div id="modal-radio-buttons" className="flex flex-column gap-dot5 modal-radio-buttons">
                      <div
                        className={`radio-button ${backupOption === 'none' ? 'radio-button-active' : ''}`}
                        onClick={() => setBackupOption('none')}
                      >
                        <span>No Backups</span>
                      </div>
                      <div
                        className={`radio-button ${backupOption === 'weekly' ? 'radio-button-active' : ''}`}
                        onClick={() => setBackupOption('weekly')}
                      >
                        <span>Weekly Backups +20%</span>
                      </div>
                      <div
                        className={`radio-button ${backupOption === 'daily' ? 'radio-button-active' : ''}`}
                        onClick={() => setBackupOption('daily')}
                      >
                        <span>Daily Backups +30%</span>
                      </div>
                    </div>
                  </div>
                  <h5>SELECT DROPLET QUANTITY</h5>
                  <input
                    ref={quantityInputRef}
                    type="number"
                    className="input"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleQuantityBlur}
                    min="1"
                    max="10000"
                  />
                </div>
              )}
            </div>
          )}
          {activeTab === 'GPU Droplets' && (
            <div>
              <h5>SELECT GPU DROPLET PLAN</h5>
              <div id="search-container" className="search-container">
                <input
                  ref={searchInputRef}
                  type="text"
                  className="input"
                  placeholder="Search plans or click to view all"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setShowDropdown(true);
                  }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
                {showDropdown && filteredGpuPlans.length > 0 && (
                  <ul id="dropdown" className="dropdown">
                    {filteredGpuPlans.map((plan, index) => (
                      <li key={index} onClick={() => handlePlanSelect(plan)} className="flex font-darkGray ">
                        <div className="dropdown-plans-description">
                          {plan.Description}
                        </div>
                        <div className="dropdown-plans-specifications">
                          {plan.VCPUs} vCPU, {plan.Memory} GB Memory, {plan.Disk} GiB Boot Disk
                        </div>
                        <div id="price" className="price">
                          <span className="dollar">Per GPU $</span>
                          <span>
                            {formatPrice(plan['Price Hourly'])}
                          </span>
                          <span className="period">/hr</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {showDropdown && filteredGpuPlans.length === 0 && (
                  <ul id="dropdown" className="dropdown">
                    <li>No plans found</li>
                  </ul>
                )}
              </div>
              {selectedPlan && (
                <div>
                  <div id="card" className="card">
                    <div id="card-header" className="card-header flex flex-between flex-center">
                      <div>
                        <h5>SELECTED PLAN</h5>
                      </div>
                      <div id="price" className="price">
                        <span className="dollar">Per GPU $</span>
                        <span className="cost">{formatPrice(calculateTotalGpuDropletPrice().replace(/,/g, '') / quantity / gpuHours / selectedPlan.GPU)}</span>
                        <span className="period">/hr</span>
                      </div>
                    </div>
                    <div id="card-body" className="card-body">
                      <p>{selectedPlan.Description}</p>
                      <p>{selectedPlan.VCPUs} vCPU / {selectedPlan.Memory} GiB Memory / {selectedPlan.Disk} GiB Boot Disk / {selectedPlan.Transfer} GiB Egress</p>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div>
                      <h5>SELECT BILLING OPTION</h5>
                      <div id="modal-radio-buttons" className="flex gap-dot5 modal-radio-buttons">
                        <div
                          className={`radio-button ${billingOption === 'on-demand' ? 'radio-button-active' : ''}`}
                          onClick={() => handleBillingOptionChange('on-demand')}
                        >
                          <span>On-demand</span>
                        </div>
                        <div
                          className={`radio-button ${billingOption === 'contracted' ? 'radio-button-active' : ''}`}
                          onClick={() => handleBillingOptionChange('contracted')}
                        >
                          <span>Contracted</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {billingOption === 'contracted' && (
                    <div>
                      <h5>ENTER CONTRACTED RATE</h5>
                      <div id="modal-negotiated-price" className="flex gap-2 flex-center">
                        <div className="negotiated-price-input-wrapper">
                          <span className="negotiated-dollar-sign">$</span>
                          <input
                            type="number"
                            className="input negotiated-price-input"
                            value={negotiatedPrice}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= parseFloat(selectedPlan['Price Hourly']))) {
                                setNegotiatedPrice(value);
                              }
                            }}
                            onBlur={() => {
                              const price = parseFloat(negotiatedPrice);
                              const planPrice = parseFloat(selectedPlan['Price Hourly']);
                              if (isNaN(price) || price < 0 || price > planPrice) {
                                setNegotiatedPrice(planPrice);
                              }
                            }}                              
                            placeholder=""
                            step="0.01"
                            min="0"
                            max={selectedPlan ? selectedPlan['Price Hourly'] : ''} // Set max to the selected plan's price
                          />
                        </div>
                        <div className='font-size-dot8'>
                          <p className="font-weight-600">Price Range</p>
                          <p>$0 - ${selectedPlan['Price Hourly']}</p>
                        </div>
                        <a
                          href="#"
                          class="action-text"
                          onClick={(e) => {
                            e.preventDefault();
                            setNegotiatedPrice(selectedPlan['Price Hourly']);
                          }}
                        >
                          Reset
                        </a>
                      </div>
                    </div>
                  )}
                  <h5>SELECT HOURS PER MONTH</h5>
                  <div className="flex flex-start flex-center gap-2">
                    <div className="stepper-container">
                      <div className="stepper-input-wrapper">
                        <div className="stepper-input">
                          <input 
                            type="number" 
                            className="stepper-input-field" 
                            name="gpuHours-count" 
                            placeholder="" 
                            min={minGpuHours}
                            max={maxGpuHours}
                            step="10"
                            aria-invalid="false" 
                            autoComplete="off" 
                            spellCheck="false" 
                            value={gpuHours} // Bind the input to state
                            onChange={(e) => {
                              const value = e.target.value;

                              // Allow the field to be empty
                              if (value === '') {
                                setGpuHours('');
                                return;
                              }

                              const numericValue = parseInt(value, 10);

                              // If the input is not a valid number or exceeds max hours, do nothing
                              if (isNaN(numericValue) || numericValue > maxGpuHours) {
                                return;
                              }

                              setGpuHours(numericValue);
                            }}
                            onBlur={(e) => {
                              if (!selectedPlan) return; // Ensure a plan is selected before enforcing limits
                          
                              let value = parseInt(e.target.value, 10);
                          
                              // If the input is empty, reset to the minimum value without rounding up
                              if (isNaN(value)) {
                                  setGpuHours(minGpuHours);
                                  calculateTotalGpuDropletPrice(); // Calculate price on blur
                                  return;
                              }
                          
                              // Enforce minimum and maximum constraints without rounding up
                              if (value <= minGpuHours) {
                                  setGpuHours(minGpuHours);
                                  calculateTotalGpuDropletPrice(); // Calculate price on blur
                              } else if (value >= maxGpuHours) {
                                  setGpuHours(maxGpuHours);
                                  calculateTotalGpuDropletPrice(); // Calculate price on blur
                              } else {
                                  setGpuHours(value);
                                  calculateTotalGpuDropletPrice(); // Calculate price on blur
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="stepper-button-wrapper-up">
                        <button 
                          className="stepper-button-increment" 
                          onClick={handleGpuHoursIncrement}
                          disabled={gpuHours === maxGpuHours}
                        >
                          <svg className="stepper-icon-up" aria-hidden="true">
                            <use href="#_sprite-arrow-up"></use>
                          </svg>
                        </button>
                      </div>
                      <div className="stepper-button-wrapper-down">
                        <button 
                          className="stepper-button-decrement" 
                          onClick={handleGpuHoursDecrement}
                          disabled={gpuHours === minGpuHours}
                        >
                          <svg className="stepper-icon-down" aria-hidden="true">
                            <use href="#_sprite-arrow-down"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className='font-size-dot8'>
                      <p className="font-weight-600">Hour Range</p>
                      <p>{minGpuHours} - {maxGpuHours}</p>
                    </div>
                  </div>
                  <h5>SELECT DROPLET QUANTITY</h5>
                  <input
                    ref={quantityInputRef}
                    type="number"
                    className="input"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleQuantityBlur}
                    min="1"
                    max="10000"
                  />
                </div>
              )}

            </div>
          )}
          {activeTab === 'Egress Overage' && (
            <div>
              <h5>SELECT OVERAGE SIZE</h5>
              <div id="calculation-container" className="calculation-container">
                <div id="calculation-container-price" className="calculation-container-price">
                  <div className="price">
                    <span className="dollar dollar-large">$</span>
                    <span className="cost cost-large">{overage ? formatPrice(parseInt(overage, 10) * overagePrice) : '0'}</span>
                    <span className="period period-large">/mo</span>
                  </div>
                  <div>
                    <span>
                      {overage >= 1024
                        ? `${((overage) / 1024).toFixed(2).replace(/\.0+$/, '')} PiB (${((overage) / 1024 * conversionFactorPiBtoPB).toFixed(2).replace(/\.0+$/, '')} PB)`
                        : overage > 0
                        ? `${overage} TiB (${(overage * conversionFactorTiBtoTB).toFixed(0)} TB)`
                        : `_ TiB ( _ TB)`}
                    </span>
                  </div>
                </div>
                <div id="calculation-container-input" className="calculation-container-input">
                  <input 
                    type="text" 
                    spellCheck="false" 
                    required="" 
                    placeholder="Enter overage in TiB"  
                    value={overage}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) { // Only allow digits
                        const numValue = parseInt(value, 10);
                        if (!isNaN(numValue) && numValue >= 0 && numValue <= 10240) {
                          setOverage(value);
                        } else if (value === '') {
                          setOverage('');
                        }
                      }
                    }}
                  />
                  <div id="floatlabel-price" className="floatlabel-price">
                    <label>Enter overage in TiB</label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div id="modal-footer" className="modal-footer flex flex-between flex-center">
          <div id="action-button" className="action-button">
              <button
                className="button-blue"
                onClick={() => {
                  if (activeTab === 'Droplets' && selectedPlan) {
                    handleAddDroplets();
                  } else if (activeTab === 'GPU Droplets' && selectedPlan) {
                    handleAddGpuDroplets();
                  } else if (activeTab === 'Egress Overage' && overage) {
                    handleAddOverage();
                  }
                }}
                disabled={ 
                  (activeTab === 'Droplets' && !selectedPlan) || 
                  (activeTab === 'GPU Droplets' && !selectedPlan) ||                   
                  (activeTab === 'Egress Overage' && !overage)
                }
              >
                {activeTab === 'Droplets' && <span>Add Droplets</span>}
                {activeTab === 'GPU Droplets' && <span>Add GPU Droplets</span>}
                {activeTab === 'Egress Overage' && <span>Add Overage</span>}
              </button>
            </div>
          <div id="price" className="price">
            <span className="dollar dollar-large">$</span>
            <span className="cost cost-large">
              {
                activeTab === 'Droplets' ? (totalDropletPrice ? formatPrice(totalDropletPrice) : '0') : 
                activeTab === 'GPU Droplets' ? (totalGpuDropletPrice ? formatPrice(totalGpuDropletPrice) : '0') :
                activeTab === 'Egress Overage' ? (overage ? formatPrice(parseInt(overage, 10) * overagePrice) : '0') : '0'
              }
            </span>
            <span className="period period-large">/mo</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DropletsModal;