import React from 'react';
import './ReleaseModal.css';

function ReleaseModal({ closeModal }) {
  return (
    <div id="release-modal" className="modal">
      <div id="release-modal-content" className="modal-content">
        <span id="close" className="close" onClick={closeModal}>&times;</span>
        <div id="release-modal-header" className="modal-header">
          <h1>Release Notes</h1>
        </div>
        <div id="release-modal-body" className="modal-body flex flex-column gap-dot5">
        <div class='flex gap-1'>
            <div className="notification">March 24, 2025</div>
            <div>Added GenAI with Agents, Knowledge Bases and Guardrails.</div>
          </div>
          <div class='flex gap-1'>
            <div className="notification">March 18, 2025</div>
            <div>Added Network Load Balancers.</div>
          </div>
          <div class='flex gap-1'>
            <div className="notification">March 13, 2025</div>
            <div>Added contracted options to GPU Droplets and Dedicated GPUs.</div>
          </div>
          <div class='flex gap-1'>
            <div className="notification">February 10, 2025</div>
            <div>Added Bare Metal GPUs.</div>
          </div>
          <div class='flex gap-1'>
            <div className="notification">February 8, 2025</div>
            <div>Added VPC Peering.</div>
          </div>
          <div class='flex gap-1'>
            <div className="notification">February 5, 2025</div>
            <div>Added Container Registry.</div>
          </div>
          <div class='flex gap-1'>
            <div className="notification">September 6, 2024</div>
            <div>Added GPU Droplets.</div>
          </div>
          <div class='flex gap-1'>
            <div className="notification">August 28, 2024</div>
            <div>Added App Platform.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseModal;
