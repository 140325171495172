import React, { useState, useRef, useEffect } from 'react';
import './BaremetalModal.css';

function BaremetalModal({ closeModal, addQuoteItem, formatPrice, plansBaremetal }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [name, setName] = useState(''); // New state for the name input
  const searchInputRef = useRef(null);
  const quantityInputRef = useRef(null);
  const maxGpuHours = 744;
  const minGpuHours = 1;
  const [gpuHours, setGpuHours] = useState(maxGpuHours);
  const [activeTab, setActiveTab] = useState('Bare Metal GPUs');
  const [negotiatedPrice, setNegotiatedPrice] = useState('');
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSelectedPlan(null);
    setShowDropdown(false);
    setGpuHours(maxGpuHours);
  };

  // Focus and select the quantity input when a plan is selected
  useEffect(() => {
    if (selectedPlan) {
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  }, [selectedPlan]);

  // Filter Bare Metal GPU plans based on the search term by applying regex across various plan attributes
  const filteredBaremetalPlans = plansBaremetal.filter(plan => {

    // Create a regex from the search term, escaping special characters to prevent regex errors
    const regex = new RegExp(searchTerm.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    
    // Test regex against plan fields, converting numeric fields to string with descriptive text
    return (
      regex.test(plan.Description) ||  // Check plan description
      regex.test(`${plan.CPUs} CPUs`) ||  // Check CPUs with 'CPU' appended
      regex.test(`${plan['GPU Memory']} GB GPU Memory`) ||  // Check memory size with 'GB GPU Memory' appended
      regex.test(`${plan['CPU Memory']} GB CPU Memory`) ||  // Check memory size with 'GB CPU Memory' appended
      regex.test(`$${plan['Price Hourly']} Hourly`)  // Check monthly price with 'Hourly' appended
    );
  });

  // Handle plan selection
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setShowDropdown(false);
    setSearchTerm(''); // Clear the search term after selection
    setQuantity(1); // Set default quantity to 1
    setNegotiatedPrice(plan['Price Hourly']); // Set default negotiated price using the selected plan
  };

  // Handle quantity change
  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 1 && Number(value) <= 10000)) {
      setQuantity(value);
    }
  };

  // Handle quantity blur (when input loses focus)
  const handleQuantityBlur = () => {
    if (quantity === '' || quantity < 1) {
      setQuantity(1);
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  };

  // Calculate the total Bare Metal GPU price
  const calculateTotalBareMetalGPUsPrice = () => {
    if (!selectedPlan) return 0;
    let price = parseFloat(negotiatedPrice) !== parseFloat(selectedPlan['Price Hourly']) ? parseFloat(negotiatedPrice) : parseFloat(selectedPlan['Price Hourly']);
    price *= gpuHours;
    price *= selectedPlan.GPUs;
    return formatPrice(price * (quantity || 1));
  };

  const totalBareMetalGPUsPrice = calculateTotalBareMetalGPUsPrice();  

  // Function to increment the GPU hours by 1, capped at the maximum limit
  const handleGpuHoursIncrement = () => {
    setGpuHours(gpuHours+1);
    calculateTotalBareMetalGPUsPrice();
  };

  // Function to decrement the GPU hours by 1, capped at the minimum limit
  const handleGpuHoursDecrement = () => {
    setGpuHours(gpuHours-1);
    calculateTotalBareMetalGPUsPrice();
  };

  // Handle adding the selected Bare Metal GPU plan to the quote
  const handleAddBareMetalGPUs = () => {
    let description = `${selectedPlan.Description} - ${selectedPlan.GPUs}x GPUs, ${selectedPlan['GPU Memory']} GB GPU Memory, ${selectedPlan.Storage} TB Storage.`;
    let features = `Bare Metal GPUs with ${gpuHours} hour${(gpuHours % 10 === 1 && gpuHours % 100 !== 11) ? '' : 's'} of usage per month at $${negotiatedPrice} per GPU.`;

    addQuoteItem({
      serviceType: 'Bare Metal GPUs',
      name: name, // Use the value from the new input field
      description: description,
      features: features,
      transfer: selectedPlan.Transfer * 1/1024,
      priceMonthly: formatPrice(calculateTotalBareMetalGPUsPrice().replace(/,/g, '') / quantity),
      quantity: quantity || 1,
      totalPrice: totalBareMetalGPUsPrice
    });
    closeModal(); // Close the modal after adding the quote item
  };

  return (
    <div id="modal" className="modal">
      <div id="modal-content" className="modal-content droplets-modal">
        <span id="close" className="close" onClick={closeModal}>&times;</span>
        <div id="modal-header" className="modal-header flex flex-between flex-center">
          <div>
            <h1>Add Bare Metal</h1>
          </div>
          <div id="modal-item-name" className="modal-item-name">
            <input
              type="text"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter item name or description"
              maxLength="50" // Added maxLength attribute
            />
          </div>
        </div>
        <div id="modal-tabs" className="modal-tabs">
          <button
            className={`tab-button ${activeTab === 'Bare Metal GPUs' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Bare Metal GPUs')}
          >
            Bare Metal GPUs
          </button>
        </div>
        <div id="modal-body" className="modal-body">
          {activeTab === 'Bare Metal GPUs' && (
            <div>
              <h5>SELECT BARE METAL GPU PLAN</h5>
              <div id="search-container" className="search-container">
                <input
                  ref={searchInputRef}
                  type="text"
                  className="input"
                  placeholder="Search plans or click to view all"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setShowDropdown(true);
                  }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
                {showDropdown && filteredBaremetalPlans.length > 0 && (
                  <ul id="dropdown-baremetal" className="dropdown">
                    {filteredBaremetalPlans.map((plan, index) => (
                      <li key={index} onClick={() => handlePlanSelect(plan)} className="flex font-darkGray ">
                        <div className="dropdown-plans-description">
                          {plan.Description}
                        </div>
                        <div className="dropdown-plans-specifications">
                          {plan.GPUs}x GPUs, {plan['GPU Memory']} GB GPU Memory, {plan.Storage} TB Storage
                        </div>
                        <div id="price" className="price">
                          <span className="dollar">Per GPU $</span>
                          <span>
                            {formatPrice(plan['Price Hourly'])}
                          </span>
                          <span className="period">/hr</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {showDropdown && filteredBaremetalPlans.length === 0 && (
                  <ul id="dropdown" className="dropdown">
                    <li>No plans found</li>
                  </ul>
                )}
              </div>
              {selectedPlan && (
                <div>
                  <div id="card" className="card">
                    <div id="card-header" className="card-header flex flex-between flex-center">
                      <div>
                        <h5>SELECTED PLAN</h5>
                      </div>
                      <div id="price" className="price">
                        <span className="dollar">Per GPU $</span>
                        <span className="cost">{formatPrice(calculateTotalBareMetalGPUsPrice().replace(/,/g, '') / quantity / gpuHours / selectedPlan.GPUs)}</span>
                        <span className="period">/hr</span>
                      </div>
                    </div>
                    <div id="card-body" className="card-body">
                      <p>{selectedPlan.Description}</p>
                      <p>{selectedPlan.GPUs}x GPUs / {selectedPlan['GPU Memory']} GB GPU Memory / {selectedPlan.Storage} TB Storage</p>
                    </div>
                  </div>
                  <h5>ENTER CONTRACTED RATE PER GPU</h5>
                  <div id="modal-negotiated-price" className="flex gap-2 flex-center">
                    <div className="negotiated-price-input-wrapper">
                      <span className="negotiated-dollar-sign">$</span>
                      <input
                        type="number"
                        className="input negotiated-price-input"
                        value={negotiatedPrice}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= parseFloat(selectedPlan['Price Hourly']))) {
                            setNegotiatedPrice(value);
                          }
                        }}
                        onBlur={() => {
                          const price = parseFloat(negotiatedPrice);
                          const planPrice = parseFloat(selectedPlan['Price Hourly']);
                          if (isNaN(price) || price < 0 || price > planPrice) {
                            setNegotiatedPrice(planPrice);
                          }
                        }}                              
                        step="0.01"
                        min="0"
                        max={selectedPlan ? selectedPlan['Price Hourly'] : ''} // Set max to the selected plan's price
                      />
                    </div>
                    <div className='font-size-dot8'>
                      <p className="font-weight-600">Price Range</p>
                      <p>$0 - ${selectedPlan['Price Hourly']}</p>
                    </div>
                    <a
                      href="#"
                      class="action-text"
                      onClick={(e) => {
                        e.preventDefault();
                        setNegotiatedPrice(selectedPlan['Price Hourly']);
                      }}
                    >
                      Reset
                    </a>
                  </div>
                  <h5>SELECT HOURS PER MONTH</h5>
                  <div className="flex flex-start flex-center gap-2">
                    <div className="stepper-container">
                      <div className="stepper-input-wrapper">
                          <div className="stepper-input">
                              <input 
                              type="number" 
                              className="stepper-input-field" 
                              name="gpuHours-count" 
                              placeholder="" 
                              min={minGpuHours}
                              max={maxGpuHours}
                              step="10"
                              aria-invalid="false" 
                              autoComplete="off" 
                              spellCheck="false" 
                              value={gpuHours} // Bind the input to state
                              onChange={(e) => {
                                  const value = e.target.value;

                                  // Allow the field to be empty
                                  if (value === '') {
                                  setGpuHours('');
                                  return;
                                  }

                                  const numericValue = parseInt(value, 10);

                                  // If the input is not a valid number, do nothing
                                  if (isNaN(numericValue) || numericValue > maxGpuHours) {
                                  return;
                                  }

                                  setGpuHours(numericValue);
                              }}
                              onBlur={(e) => {
                                if (!selectedPlan) return; // Ensure a plan is selected before enforcing limits
                            
                                let value = parseInt(e.target.value, 10);
                            
                                // If the input is empty, reset to the maximum value without rounding up
                                if (isNaN(value)) {
                                    setGpuHours(maxGpuHours);
                                    calculateTotalBareMetalGPUsPrice(); // Calculate price on blur
                                    return;
                                }
                            
                                // Enforce minimum and maximum constraints without rounding up
                                if (value <= minGpuHours) {
                                    setGpuHours(minGpuHours);
                                    calculateTotalBareMetalGPUsPrice(); // Calculate price on blur
                                } else if (value >= maxGpuHours) {
                                    setGpuHours(maxGpuHours);
                                    calculateTotalBareMetalGPUsPrice(); // Calculate price on blur
                                } else {
                                    setGpuHours(value);
                                    calculateTotalBareMetalGPUsPrice(); // Calculate price on blur
                                }
                              }}
                            />
                          </div>
                      </div>
                      <div className="stepper-button-wrapper-up">
                          <button 
                              className="stepper-button-increment" 
                              onClick={handleGpuHoursIncrement}
                              disabled={gpuHours === maxGpuHours}
                          >
                              <svg className="stepper-icon-up" aria-hidden="true">
                                  <use href="#_sprite-arrow-up"></use>
                              </svg>
                          </button>
                      </div>
                      <div className="stepper-button-wrapper-down">
                          <button 
                              className="stepper-button-decrement" 
                              onClick={handleGpuHoursDecrement}
                              disabled={gpuHours === minGpuHours}
                          >
                              <svg className="stepper-icon-down" aria-hidden="true">
                                  <use href="#_sprite-arrow-down"></use>
                              </svg>
                          </button>
                      </div>
                    </div>
                    <div className='font-size-dot8'>
                      <p className="font-weight-600">Hour Range</p>
                      <p>{minGpuHours} - {maxGpuHours}</p>
                    </div>
                  </div>
                  <h5>SELECT QUANTITY</h5>
                  <input
                    ref={quantityInputRef}
                    type="number"
                    className="input"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleQuantityBlur}
                    min="1"
                    max="10000"
                  />
                </div>
              )}

            </div>
          )}
        </div>
        <div id="modal-footer" className="modal-footer flex flex-between flex-center">
          <div id="action-button" className="action-button">
              <button
                className="button-blue"
                onClick={() => {
                  if (activeTab === 'Bare Metal GPUs' && selectedPlan) {
                    handleAddBareMetalGPUs();
                  }
                }}
                disabled={ 
                  (activeTab === 'Bare Metal GPUs' && !selectedPlan)                  
                }
              >
                {activeTab === 'Bare Metal GPUs' && <span>Add Bare Metal GPUs</span>}
              </button>
            </div>
          <div id="price" className="price">
            <span className="dollar dollar-large">$</span>
            <span className="cost cost-large">
              {
                activeTab === 'Bare Metal GPUs' ? (totalBareMetalGPUsPrice ? formatPrice(totalBareMetalGPUsPrice) : '0') : '0'
              }
            </span>
            <span className="period period-large">/mo</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BaremetalModal;