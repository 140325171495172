// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workspace-link {
  cursor: pointer; /* Change cursor to pointer */
  text-decoration: none; /* Remove underline by default */
}

.workspace-link:hover {
  text-decoration: underline; /* Add underline on hover */
}

#release-modal-body .notification {
  width: 9rem;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/modals/ReleaseModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,6BAA6B;EAC9C,qBAAqB,EAAE,gCAAgC;AACzD;;AAEA;EACE,0BAA0B,EAAE,2BAA2B;AACzD;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".workspace-link {\n  cursor: pointer; /* Change cursor to pointer */\n  text-decoration: none; /* Remove underline by default */\n}\n\n.workspace-link:hover {\n  text-decoration: underline; /* Add underline on hover */\n}\n\n#release-modal-body .notification {\n  width: 9rem;\n  text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
