import { useState, useEffect } from 'react';
import Papa from 'papaparse';

export const useFetchPlans = () => {
  const [plansDroplets, setPlansDroplets] = useState([]);
  const [plansDatabases, setPlansDatabases] = useState([]);
  const [plansAppPlatform, setPlansAppPlatform] = useState([]);
  const [plansBaremetal, setPlansBaremetal] = useState([]);


  useEffect(() => {
    // Fetching droplet and DOKS plans
    fetch('/assets/pricing/droplet.csv')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(text => {
        Papa.parse(text, {
          header: true,
          complete: (result) => {
            setPlansDroplets(result.data); // Set the plans state with parsed CSV data
          }
        });
      })
      .catch(error => {
        console.error('Error fetching the droplet CSV file:', error);
      });

    // Fetching DBaaS plans
    fetch('/assets/pricing/dbaas.csv')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(text => {
        Papa.parse(text, {
          header: true,
          complete: (result) => {
            setPlansDatabases(result.data); // Set the DBaaS plans state with parsed CSV data
          }
        });
      })
      .catch(error => {
        console.error('Error fetching the DBaaS CSV file:', error);
      });


      // Fetching AppPLatform plans
      fetch('/assets/pricing/ap.csv')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(text => {
        Papa.parse(text, {
          header: true,
          complete: (result) => {
            setPlansAppPlatform(result.data); // Set the App Platform plans state with parsed CSV data
          }
        });
      })
      .catch(error => {
        console.error('Error fetching the DBaaS CSV file:', error);
      });

      // Fetching Baremetal plans
      fetch('/assets/pricing/baremetal.csv')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(text => {
        Papa.parse(text, {
          header: true,
          complete: (result) => {
            setPlansBaremetal(result.data);
          }
        });
      })
      .catch(error => {
        console.error('Error fetching the Baremetal CSV file:', error);
      });

  },
  []);

  return { plansDroplets, plansDatabases, plansAppPlatform, plansBaremetal }; // Return plans
};